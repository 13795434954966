import React, {useContext,  useState} from 'react';
import { Form, Button, Select, Tag,  } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FilterValues } from '../../Services/useGetLeakedNotifications';
import './styles.scss'
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useGetMachinesSimplifiedList } from '../../Services/useGetMachinesSimplifiedList';
import { useGetOrganizationsSimplifiedList } from '../../Services/useGetOrganizationsSimplifiedList';
import {  MaintenanceAlertFiltersProps } from './type';
import { useGetDealershipSimplifiedList } from '../../Services/useGetDealershipSimplifiedList';

const optionsColors = [
  { value: 'red', label: 'Rojo' }, 
  { value: 'blue', label:'Azul' }, 
  { value: 'gray', label:'Gris' }, 
  { value: 'yellow', label:'Amarillo'}
];

const tagRender = (props: CustomTagProps) => {

    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };    

    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };  

export interface MainenanceAlertsFilters {
  machines:  FilterValues[] | undefined,
  organizations: FilterValues| undefined,
  dealership : FilterValues| undefined,
}

export interface OrganizationsForSelector{
  value: string
  label: string
}

export const MaintenanceAlertFilters: React.FC <MaintenanceAlertFiltersProps>=({
  form_maintenance,
  handleGetPage,
  loadingTable,
  handleSeeAll
})=>{

  const { setDataLeakedNotifications} = useContext(BodyContext)
  const {data: dealerships} = useGetDealershipSimplifiedList()
  const { data: machines } = useGetMachinesSimplifiedList()
  const { Option } = Select;

  const {data: organizationsAll}= useGetOrganizationsSimplifiedList()
  const [loadingFilters, setLoadingFilters] = useState(false)
 


  const handleApplyFiltros = () =>{
    handleGetPage(1, true)
  }

  return(
      <div className="maintenanceAlertFilters_container">
          <p><div className='alertsFilters_point'> </div> FILTROS</p>
          <Form
            form={form_maintenance}
            onFinish={handleApplyFiltros}  
          >       
            {/* <Form.Item 
              name='colors'
              className="maintenanceAlertFilters_selectColor">
                <Select
                    mode="multiple"
                    tagRender={tagRender}
                    style={{ width: '100%'}}
                    options={optionsColors}
                    placeholder="Tipo de notificación"
                />             
            </Form.Item > */}
            <Form.Item 
              name='machines'
              className="maintenanceAlertFilters_selectOrganizations">
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                labelInValue
                tokenSeparators={[',']}
                placeholder="Maquinaria"
              >
                 {machines &&
                machines.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select>  
            </Form.Item>
            <Form.Item
              name='organizations' 
              className="maintenanceAlertFilters_selectOrganizations">
              <Select            
                placeholder= "Organización"
                labelInValue
                showSearch
                style={{ width: '100%' }}
                allowClear
              > 
              {organizationsAll &&
                organizationsAll.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select> 
            </Form.Item>
            <Form.Item
              name='dealership' 
              className="maintenanceAlertFilters_selectOrganizations">
              <Select            
                placeholder= "Sucursal"
                labelInValue
                showSearch
                style={{ width: '100%' }}
                allowClear
              > 
              {dealerships &&
                dealerships.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select> 
            </Form.Item>
            <Form.Item>
              <Button
                className="maintenanceAlertFilters_filtersBtn"
                htmlType="submit"
                disabled={loadingTable}
              >
                Aplicar
              </Button>
            </Form.Item>
          </Form>
          <Button
                className="maintenanceAlertFilters_filtersBtn"
                onClick={handleSeeAll}
                disabled={loadingTable}
              >
                Ver todos
              </Button>
      </div>
    )
}

export default MaintenanceAlertFilters;

