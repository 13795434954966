import { useContext} from 'react';
import { messagesLogin } from './messages';
import { useIntl } from 'react-intl';
import { useGetMyJohnDeere } from '../../Services/useGetMyJohnDeere';
import { UserContext } from '../../Context/UserContext/UsersContext';
import './login.scss';

export const Login = ()=> {
     const {
       setLoading
    } = useContext(UserContext)
    const intl = useIntl();
    const {getMyJohnDeere} = useGetMyJohnDeere()
    
    // const [form,setForm] = useState({userName:'', password:''})
    // const [okMail, setOkMail] = useState(false);// this "useState" is to validate the mail characters
    // const [showMessageError, setShowMessageError] = useState<string>('')


    // const handleChange = (event: any)=>{
    //     const name = event.target.name 
    //     const value = event.target.value
    //     setForm({...form,[name]:value})
    // };

    // useEffect(()=>{
    //     if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(form.userName)){
    //          setOkMail(true)
    //      } else {
    //          setOkMail(false)    
    //      }
    //  },[form.userName]);
    

  return (
    <div className= 'login-container' >
        <h1 className='login-h '>
            {intl.formatMessage(messagesLogin.title)}
        </h1>
        <div className='login-containerBtn'>
            <button 
                style={{marginTop:'15px'}}
                className='login-btnLogin'
                onClick={()=>{
                    setLoading(true)
                    getMyJohnDeere(setLoading)
                    
                }} 
            > 
                MyJohnDeere
            </button>    
        </div>
       
    </div>
    );
}

export default Login;
