import { axiosMyJohnDeereInstance } from '../Axios';
import{AUTH_END_POINT_GET_JD_CONNECT} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';

export const useGetMyJohnDeere =  ()=>{
    
    const getMyJohnDeere = async(setLoading: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.get(AUTH_END_POINT_GET_JD_CONNECT)
           if(data){
            window.location.replace(data.data)
           }    
        }catch(e){
            setLoading(false)
            notification.error({
                message: 'Error',
                description:
                  'Hubo un error, por favor intente más tarde o comuníquese con el administrador', 
                placement: 'top'
            })
        } 
    }
    
   return{getMyJohnDeere}
}