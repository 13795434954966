import React, {
    createContext,
    useEffect,
    useState,
  } from 'react';

  class VoidContext implements DealershipContext {
    get openEditDealership(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOpenEditDealership(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedDealership(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedDealership(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface DealershipContext {
    openEditDealership: boolean,
    setOpenEditDealership: Function,
    selectedDealership: SelectedDealership | undefined,
    setSelectedDealership: Function
  }


  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface SelectedMaintenanceCofigurations{
    id: number, 
    name: string,
    hours_before_alert?: number,
    maintenance_hours?: number,
    range_hours?: number,
  }
  
  export interface SelectedDealership{
    name: string,
    address: string
    id: number
  }

   
  export const DealershipContext = createContext<DealershipContext>(new VoidContext());
  export const DealershipContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [openEditDealership, setOpenEditDealership] = useState<boolean>(false)
    const [selectedDealership, setSelectedDealership] = useState<SelectedDealership | undefined>()
      
 
    return (
      <DealershipContext.Provider value={{
        openEditDealership,
        setOpenEditDealership,
        selectedDealership, 
        setSelectedDealership

      }}>{children}</DealershipContext.Provider>
    );
  };



  