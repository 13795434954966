import React, { useContext, useEffect, useState } from 'react';
import  { Button, Table } from  'antd';
import { BankOutlined} from '@ant-design/icons';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { useGetAllDealershep } from '../../Services/useGetAllDealershep';
import { OrganizationModal } from '../OrganizationModal/OrganizationModal';
import './styles.scss'
import { ColumnsType } from 'antd/es/table';
import { DealershipContext } from '../../Context/DealershipContext/DealershipContext';
import { RowDealership } from './type';
import DealershipsEditModal from '../DealershipsEditModal/DealershipsEditModal';

export interface Dealership{
    address: string,
    id: number,
    name: string,
    created?: string,
    is_active?: boolean,
}

export const Dealerships: React.FC =()=>{

    const {data: dealerships} = useGetAllDealershep()
    const {
        openEditDealership, 
        setOpenEditDealership,  
        setSelectedDealership
    } = useContext(DealershipContext)

    const [openModalCreate, setOpenModalCreate] = useState<boolean>(false)
    const [tablaData, setTablaData] = useState<Dealership[]>([])
    const [loadingTable, setLoadingTable] = useState<boolean>(true)

    useEffect(()=>{
        if(dealerships){
          rowData()      
          setLoadingTable(false)
        }
    },[dealerships])

    const rowData = ()=>{
        let list: Dealership[] =[]
        if(dealerships){
            dealerships.results.map((item)=>{
                list.push({
                    address: item.address,
                    id: item.id,
                    name: item.name,
                    created: item.created,
                    is_active: item.is_active,
                })
            })
           
        }
        setTablaData(list)
    }

    const handleRenderer = (text: string, event: RowDealership,  ) =>{
        return <a
            onClick={()=>{
              
            }}
        >{text}</a>
    }

    const machineryfilters = ()=>{
        let textAndValue: {text: string, value: string}[] =[]
        dealerships?.results.map((item)=>{
            textAndValue.push({
                text: item.name,
                value:  item.name
            })
        })
        return textAndValue
    }

    const callback = () =>{
        setLoadingTable(false)
      }

    const columns: ColumnsType<Dealership> = [
        {
            title: 'Sucursal',
            dataIndex: 'name',
            key: 'id',
            render: handleRenderer,
            filters: machineryfilters(),
            onFilter: (value, record) => typeof value === 'string' && record.name.includes(value),
            ellipsis: true
        },
        {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'id',
            render: handleRenderer,
            ellipsis: true            
        },
    ];

    const onClickRow = (record: Dealership) =>{
        setOpenEditDealership(true)
        setSelectedDealership({
            name: record.name,
            address: record.address,
            id: record.id
        })
    }

    return(
        <div className="dealerships_container">
            <div className='dealerships_title_container'>         
                <BankOutlined style={{ fontSize: '18px', color: 'white', marginLeft:'10px'}} /> 
                <p>SUCURSALES</p>
            </div>
            <div className='dealerships_body_container'>
                <div className='dealerships_submenu'>
                    <div className='dealerships_subtitle'>
                        <p><div className='dealerships_point'> </div> MENU</p>
                    </div>
                    <Button
                        className="dealerships_Btn"
                        htmlType="submit"
                        onClick={()=>{
                            setOpenModalCreate(true)
                        }}
                    >
                        Crear sucursal
                    </Button>
                </div>
                <div className='dealerships_timeline'> 
                    {
                        loadingTable 
                            ? <SpinerHorizontal/>  
                            :  <Table 
                            columns={columns} 
                            dataSource={tablaData}  
                            pagination={{ pageSize: 11 }}
                            onRow={(record, index) => {
                                return {
                                    onClick: (event) => {
                                        onClickRow(record)
                                    }
                                };
                            }}
                        />                
                    } 
                </div>  
                {openEditDealership && <DealershipsEditModal/>}
                {openModalCreate && <OrganizationModal setOpenModalCreate={setOpenModalCreate} openModalCreate={openModalCreate}/>}

            </div>
        </div>
    )
}

export default Dealerships;

