import { defineMessages } from 'react-intl';

export const group = 'component.messagesLogin';

export const messagesLogin = defineMessages({
  title: {
    id: `${group}.title`,
    defaultMessage: "INICIAR SESIÓN",
  },
  forgotPassword: {
    id: `${group}.forgotPassword`,
    defaultMessage: "Forgot password?",
  },
  account: {
    id: `${group}.account`,
    defaultMessage: "Don't have an account?",
  },
  buttonSignUp: {
    id: `${group}.buttonSignUp`,
    defaultMessage: "Sign up",
  },
  incompleteMail: {
    id: `${group}.incompleteMail`,
    defaultMessage: "The email entered is not correct.",
  },
  completeFields: {
    id: `${group}.completeFields`,
    defaultMessage: "You must complete all fields!",
  }, 
  placeholderEmail: {
    id: `${group}.placeholderEmail`,
    defaultMessage: "Email",
  },
  placeholderPassword: {
    id: `${group}.placeholderPassword`,
    defaultMessage: "Password",
  },
  buttonLogin: {
    id: `${group}.buttonLogin`,
    defaultMessage: "Ingresar",
  },
  });