import React from 'react';
import { Form, Button, DatePicker, Select, } from 'antd';
import './styles.scss'
import { useGetMachinesSimplifiedList } from '../../Services/useGetMachinesSimplifiedList';
import { useGetOrganizationsSimplifiedList } from '../../Services/useGetOrganizationsSimplifiedList';
import { useGetDealershipSimplifiedList } from '../../Services/useGetDealershipSimplifiedList';
import locale from 'antd/es/date-picker/locale/es_ES';
import { MaintenanceCarriedOutFiltersProps } from './type';


export interface OrganizationsForSelector{
  value: string
  label: string
}

export const MaintenanceCarriedOutFilters: React.FC <MaintenanceCarriedOutFiltersProps>=({
  form_maintenanceCarriedOut,
  handleGetPage,
  loadingTable,
  handleSeeAll
})=>{

  const { data: dealerships } = useGetDealershipSimplifiedList()
  const { data: machines } = useGetMachinesSimplifiedList()
  const { Option } = Select;
  const { data: organizationsAll } = useGetOrganizationsSimplifiedList()
  const { RangePicker } = DatePicker;
 

  const handleApplyFiltros = () =>{
    handleGetPage(undefined, true)
  }

  return(
      <div className="maintenanceCarriedOutFilters_container">
          <p><div className='alertsFilters_point'></div> FILTROS</p>
          <Form
            form={form_maintenanceCarriedOut}
            onFinish={handleApplyFiltros}  
          >      
            <Form.Item 
              name='datePicker'
              className="alertsFilters_datePicker"
              >        
                <RangePicker  
                  style={{ width: '100%'}}
                  locale={locale}
                />
            </Form.Item>
            <Form.Item 
              name='machines'
              className="maintenanceCarriedOutFilters_selectOrganizations">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                labelInValue
                tokenSeparators={[',']}
                placeholder="Maquinaria"
              >
                 {machines &&
                machines.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select>  
            </Form.Item>
            <Form.Item
              name='organizations' 
              className="maintenanceCarriedOutFilters_selectOrganizations">
              <Select            
                placeholder= "Organización"
                labelInValue
                showSearch
                style={{ width: '100%' }}
                allowClear
              > 
              {organizationsAll &&
                organizationsAll.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select> 
            </Form.Item>
            <Form.Item
              name='dealership' 
              className="maintenanceCarriedOutFilters_selectOrganizations">
              <Select            
                placeholder= "Sucursal"
                labelInValue
                showSearch
                style={{ width: '100%' }}
                allowClear
              > 
              {dealerships &&
                dealerships.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select> 
            </Form.Item>
            <Form.Item>
              <Button
                className="maintenanceCarriedOutFilters_filtersBtn"
                htmlType="submit"
                disabled={loadingTable}
              >
                Aplicar
              </Button>
            </Form.Item>
          </Form>
          <Button
                className="maintenanceCarriedOutFilters_filtersBtn"
                onClick={handleSeeAll}
                disabled={loadingTable}
              >
                Ver todos
              </Button>
      </div>
    )
}

export default MaintenanceCarriedOutFilters;

