import React, {
    createContext,
    useState,
  } from 'react';
import { FilterValues } from '../../Services/useGetLeakedNotifications';

  class VoidContext implements MachineryContext {
    get layerBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showMenuHorizontal(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowMenuHorizontal(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showCheck(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowCheck(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get doRefreshMachinery(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDoRefreshMachinery(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showSelectors(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowSelectors(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedDealerShips(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedDealerShips(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get handleEnableCheck(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get loadingBtnSave(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setloadingBtnSave(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get isModalOpen(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setIsModalOpen(): never {
      throw new Error('Cannot consume context outside of provider');
    }  
    get dataMaintenanceAlert(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataMaintenanceAlert(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  type LayerBody = | 'home' |'alert' | 'organizations' | 'machinery' | 'configurations' 


  interface MachineryContext {
    showMenuHorizontal: boolean,
    setShowMenuHorizontal: Function
    showCheck: boolean,
    setShowCheck: Function,
    doRefreshMachinery: boolean,
    setDoRefreshMachinery: Function,
    showSelectors: boolean,
    setShowSelectors: Function,
    selectedDealerShips: number | undefined,
    setSelectedDealerShips: Function,
    handleEnableCheck:  () => void,
    loadingBtnSave: boolean, 
    setloadingBtnSave: Function,
    isModalOpen: boolean,
    setIsModalOpen: Function,
    dataMaintenanceAlert: {
      page: number | undefined,
      machines:  FilterValues[] | undefined,
      organizations: FilterValues| undefined,
      dealership : FilterValues| undefined,
    } ,
    setDataMaintenanceAlert: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  interface Organizations{
    name: string
    id: number
  } 

  
  export const MachineryContext = createContext<MachineryContext>(new VoidContext());
  export const MachineryContextProvider= ({ children }: propsWithChildren): JSX.Element => {
    
    const [showMenuHorizontal, setShowMenuHorizontal] = useState<boolean>(true)
    const [showCheck, setShowCheck] = useState<boolean>(false)
    const [doRefreshMachinery, setDoRefreshMachinery] = useState<boolean>(false)
    const [showSelectors, setShowSelectors] = useState<boolean>(false)
    const [selectedDealerShips, setSelectedDealerShips] = useState<number | undefined>()
    const [loadingBtnSave, setloadingBtnSave] =useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataMaintenanceAlert, setDataMaintenanceAlert] = useState<{
      page: number | undefined,
      machines:  FilterValues[] | undefined,
      organizations: FilterValues| undefined,
      dealership : FilterValues| undefined,
    } >({
      page: undefined,
      machines: undefined,
      organizations:undefined,
      dealership:  undefined,
    })

    const handleEnableCheck=()=>{
      setShowCheck(!showCheck)
      setShowSelectors(!showSelectors)
      setSelectedDealerShips(undefined)
  }

    return (
      <MachineryContext.Provider value={{
        showMenuHorizontal, 
        setShowMenuHorizontal,
        showCheck, 
        setShowCheck,
        doRefreshMachinery,
        setDoRefreshMachinery,
        showSelectors,
        setShowSelectors,
        selectedDealerShips, 
        setSelectedDealerShips,
        handleEnableCheck,
        loadingBtnSave,
        setloadingBtnSave,
        isModalOpen,
        setIsModalOpen,
        dataMaintenanceAlert, 
        setDataMaintenanceAlert      
      }}>{children}</MachineryContext.Provider>
    );
  };



  