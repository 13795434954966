import React, { useContext } from 'react';
import './styles.scss'
import Navbar from '../../Components/Navbar/Navbar';
import Notifications, { Alerts } from '../../Components/Alerts/Alerts';
import VerticalNavbar from '../../Components/VerticalNavbar/VerticalNavbar';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import Home from '../../Components/Home/Home';
import Dealerships from '../../Components/Dealerships/Dealerships';
import MaintenanceAlert from '../../Components/MaintenanceAlert/MaintenanceAlert';

import { MaintenanceCarriedOut } from '../../Components/MaintenanceCarriedOut/MaintenanceCarriedOut';
import { MachineryTable } from '../../Components/MachineryTable/MachineryTable';
import MaintenanceConfigurations from '../../Components/MaintenanceConfigurations/MaintenanceConfigurations';

export const Dashboard: React.FC =()=>{
    
    const {
        layerBody, 
        setLayerBody,
        fullScreen,
        setFullScreen
    } = useContext(BodyContext)
    
    return(
        <div className="dashboard_container">

            { !fullScreen &&  
                <div className='dashboard_navbar'>
                    <VerticalNavbar/>
                </div>
            }
            <div className='dashboard_body'>
                <Navbar/>
                {layerBody === 'alert' &&  <Alerts/>}
                {layerBody === 'machinery' &&  <MachineryTable/>}
                {layerBody === 'dealerships' &&  <Dealerships/>}
                {layerBody === 'home' &&  <Home/>}
                {layerBody === 'maintenanceAlert' &&  <MaintenanceAlert/>}
                {layerBody === 'maintenanceConfigurations' &&  <MaintenanceConfigurations/>}
                {layerBody === 'maintenance' &&  <MaintenanceCarriedOut/>}
            </div>   
          
        </div>
    )
}