import { axiosMyJohnDeereInstance } from '../Axios';
import{ API_END_POINT_MAINTENANCE_GET_ALL} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { ResponseGetAllManntenance } from './useGetAllMantenance';
import { useErrorHandling } from './useErrorHandling';
import { MaintenanceCarriedOutFiltersType } from '../Components/MaintenanceCarriedOutFilters/type';
import { FilterValues } from './useGetLeakedNotifications';
import moment from '../../node_modules/moment/moment';

export const useMaintenanceCarriedOut =  ()=>{

    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }
    
    const getMaintenanceCarriedOut = async(page: number | undefined, callback: Function, filters: MaintenanceCarriedOutFiltersType ): Promise<ResponseGetAllManntenance | undefined>=>{
        
        var params = new URLSearchParams(); 
        let startDate;
        let endDate;
        if(filters.datePicker){
            if(filters.datePicker[0].$d ){ startDate = moment(filters.datePicker[0].$d).format('YYYY-MM-DD') }
            if(filters.datePicker[1].$d ){endDate =  moment(filters.datePicker[1].$d).format('YYYY-MM-DD')} 
        }
       
        
        page && params.append("page", page.toString())
        if(filters?.machines){
            filters.machines.map((item: FilterValues)=>{
                params.append("machine", item.key.toString())
            })           
        }
        startDate && params.append("startDate", startDate)
        endDate && params.append("endDate", endDate)
        filters?.organizations && params.append("organization", filters.organizations.key.toString())
        filters?.dealership && params.append("dealership", filters.dealership.key.toString())

        try{
            const data = await axiosMyJohnDeereInstance.get(
                API_END_POINT_MAINTENANCE_GET_ALL,
                {
                    params                    
                }                    
            )
            const status = errorHandling(data).request.status
            if(status === 200){
                callback()
                return data.data 
            }
            callback()
        }catch(e){
            callback()
            console.log('Error: ' + e)
            notificationError()
        }
    } 
    
   return{
        getMaintenanceCarriedOut
    }
}