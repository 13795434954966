import React, { useContext,  useState } from 'react';
import { Button, Modal, Input, Form,   } from  'antd';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import { useMaintenanceConfigurations } from '../../Services/useMaintenanceConfigurations';

export const MaintenanceconfigurationsEditModal =()=>{

    const {
        selectedMaintenanceConfiguration, 
        setSelectedMaintenanceconfigurations, 
        openEditMaintenanceconfiguration, 
        setOpenEditMaintenanceConfiguration
    } = useContext(MaintenanceContext)

    const {deleteMaintenanceConfigurations, editMaintenanceConfigurations} = useMaintenanceConfigurations()
    
        const [form_edit_maintenanceConfiguration] = Form.useForm();
        const [editDataMaintenance, setEditDataMaintenance] = useState<boolean>(false)
        const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

        const handleCancel =()=>{
            setOpenEditMaintenanceConfiguration(false)
            setSelectedMaintenanceconfigurations(undefined)
            form_edit_maintenanceConfiguration.resetFields()
        }
    

        const handleSave = async (e:{
            maintenance_hours: number,
            range_hours: number,
            hours_before_alert: number
        }) =>{

            if(selectedMaintenanceConfiguration){
                await editMaintenanceConfigurations(
                    selectedMaintenanceConfiguration?.id,
                    e.maintenance_hours,
                    e.range_hours,
                    e.hours_before_alert,
                    handleCancel)  
            }        
        }

        const DeletedModal = ()=>{
            return(
                <>
                <Modal   
                    title='Eliminar' 
                    open={openDeleteModal}
                    className='modal buttons_modal'
                    onCancel={()=>{
                        setOpenDeleteModal(false)
                       // setSelectedMaintenance(undefined)
                    }}
                    onOk={async()=>{
                        if(selectedMaintenanceConfiguration) await deleteMaintenanceConfigurations(selectedMaintenanceConfiguration.id, handleCancel)
                    }}
                >
                   <p>¿Está seguro que desea eliminar el tipo de mantenimiento de {selectedMaintenanceConfiguration?.name}?</p>
                   
                </Modal>
                </>
            )
        }

        return(
            <>
            <Modal   
                title={selectedMaintenanceConfiguration?.name} 
                open={openEditMaintenanceconfiguration}
                onCancel={handleCancel}
                className='modal'
                footer={[
                    <Button 
                        className='global_btn-modal-ghost'
                        key="edit" 
                        onClick={()=>{
                            setEditDataMaintenance(!editDataMaintenance)
                        }}
                    >
                        {editDataMaintenance ? 'Cancelar edición' : 'Editar' }
                    </Button>,
                    <Button 
                        key="cancel" 
                        className='global_btn-modal-ghost'
                        //loading={loading}
                        onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button
                        key="save"
                        type="primary"
                        htmlType="submit"
                        className='global_btn-modal-primary'
                        form='form_edit_maintenanceConfiguration'
                        onClick={()=>{
                            if(!editDataMaintenance){
                                setOpenDeleteModal(true)                                
                            }
                        }}
                    >
                       { editDataMaintenance ? 'Guardar' : 'Eliminar'}
                    </Button>
                ]}
            >
                <div className='maintenanceConfigurations_seeMore'>
                    {
                        editDataMaintenance 
                        ? 
                        <Form 
                            form={form_edit_maintenanceConfiguration}
                            id='form_edit_maintenanceConfiguration'
                            layout="vertical"
                            onFinish={handleSave}
                        >    
                            <Form.Item
                                label='Servicio (hs):'
                                name='maintenance_hours'
                                initialValue={selectedMaintenanceConfiguration?.maintenance_hours}
                            >
                                <Input
                                    defaultValue={selectedMaintenanceConfiguration?.name}
                                    type='number'
                                />
                            </Form.Item>                                
                            <Form.Item
                                label='Rango de contemplación:'
                                name='range_hours' 
                                initialValue={selectedMaintenanceConfiguration?.range_hours}
                            >
                                <Input
                                    defaultValue={selectedMaintenanceConfiguration?.range_hours}
                                    type='number'
                                />
                            </Form.Item>
                            <Form.Item
                                label='Horas antes de emitir alerta:'
                                name='hours_before_alert' 
                                initialValue={selectedMaintenanceConfiguration?.hours_before_alert}
                            >
                                <Input
                                    defaultValue={selectedMaintenanceConfiguration?.hours_before_alert}
                                    type=''
                                />
                                
                            </Form.Item>
                        </Form> 
                        : 
                            <>
                            <p>Servicio: <h4>{selectedMaintenanceConfiguration?.maintenance_hours}hs</h4></p>
                            <p>Rango de contemplación: <h4>{selectedMaintenanceConfiguration?.range_hours}hs</h4></p>
                            <p>Horas antes de emitir alerta: <h4>{selectedMaintenanceConfiguration?.hours_before_alert}hs</h4></p>
                            </>
                           
                    }                    
                </div>            
            </Modal>
            {openDeleteModal && <DeletedModal/>}
            </>
        )
    }
  
export default MaintenanceconfigurationsEditModal;

