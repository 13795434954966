import { useContext } from 'react'
import { axiosMyJohnDeereInstance } from '../Axios';
import {
    API_END_POINT_MAINTENANCE_CREATE, 
    API_END_POINT_MAINTENANCE_UPDATE, 
    API_END_POINT_MAINTENANCE_DELETE, 
    API_END_POINT_MAINTENANCE_GET_ALL_ALERT
} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { MachineryContext } from '../Context/MachineryContext.tsx/MachineryContext';
import { useGetAllMantenance } from './useGetAllMantenance';
import moment from 'moment';
import { useGetAllMantenanceAlert } from './useGetAllMantenanceAlert';
import { useErrorHandling } from './useErrorHandling';
import { ResponseGetAllTypeMAintenance } from './useGetAllTypeMaintenance';

export const useMaintenance =  ()=>{

    const {myJohnDeereToken} = useContext(UserContext);
    const { mutate: updatellMaintenance } = useGetAllMantenance()
    const { mutate: updateAllMaintenanceAlert } = useGetAllMantenanceAlert()
    const { setloadingBtnSave} = useContext(MachineryContext)
    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }

    const createMantenance = async(newMaantenance: any, handleCancel: Function)=>{
        
        try{
           const data = await axiosMyJohnDeereInstance.post(
                API_END_POINT_MAINTENANCE_CREATE,
                newMaantenance,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Mantenimiento creado con éxito', 
                    placement: 'topRight'
                })
                updatellMaintenance()  
                setTimeout(()=>{
                    updateAllMaintenanceAlert()
                } , 5000)
                setloadingBtnSave(false)
                handleCancel()         
            }    
           setloadingBtnSave(false)

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setloadingBtnSave(false)
        } 
    }

    const editMaintenance = async(
        id: number, 
        data:{  
            comment: string
            date:string
            service: string
            user: number
        }, 
        callbackEdit: Function )=>{
        let dateString = moment(data.date).format('YYYY-MM-DD')
        let update ={
            comment: data.comment,
            engine_hours: data.service,
            user: data.user,
            date: dateString
        } 
        try{
           const data = await axiosMyJohnDeereInstance.patch(
            API_END_POINT_MAINTENANCE_UPDATE + id,
                update,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                const status = errorHandling(data).request.status
                if(status === 200){        
                    notification.success({
                        message: 'Éxito',
                        description:'Mantenimiento actualizado con éxito', 
                        placement: 'topRight'
                    })
                   // updatellMaintenance()
                    callbackEdit()  
                    setTimeout(()=>{
                        updateAllMaintenanceAlert()
                    } , 5000)
                    return status
                }    
                callbackEdit()                
            }    
        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            callbackEdit()
        } 
    }

    const deleteMaintenance = async(id: number, callback: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.delete(
            API_END_POINT_MAINTENANCE_DELETE + id,              
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Mantenimiento eliminado con éxito', 
                    placement: 'topRight'
                })
                updatellMaintenance()
                setTimeout(()=>{
                    updateAllMaintenanceAlert()
                } , 5000)
                callback()                 
            }    

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            callback()      
        } 
    }
    
    const nextOrPreviusMaintenancePage = async(url: string): Promise<ResponseGetAllTypeMAintenance | undefined>=>{
        let urlObj = new URL(url);
        let pageValue = urlObj.searchParams.get('page');
        try{
            const data = await axiosMyJohnDeereInstance.get(
                API_END_POINT_MAINTENANCE_GET_ALL_ALERT,
                {
                    params: {
                        page: pageValue
                    },
                }               
            )
            const status = errorHandling(data).request.status
            if(status === 200){
              return data.data 
            }
        }catch(e){
            console.log('Error: ' + e)
            notificationError()
        }
    } 
    
   return{
    createMantenance,
    editMaintenance,
    deleteMaintenance,
    nextOrPreviusMaintenancePage
    }
}