import { axiosMyJohnDeereInstance } from '../Axios';
import{
    API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING
} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { useErrorHandling } from './useErrorHandling';
import { ResponseGetMaintenanceAlert } from './useGetAllMantenanceAlert';
import { MainenanceAlertsFilters } from '../Components/MaintenanceAlertFilters/MaintenanceAlertFilters';
import { FilterValues } from './useGetLeakedNotifications';

export const useMaintenanceAlerts =  ()=>{

    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }
 

    const getMaintenanceAlert= async( page: number, callback: Function, filters?: MainenanceAlertsFilters): Promise<ResponseGetMaintenanceAlert | undefined>=>{
        var params = new URLSearchParams();
        
        params.append("page", page.toString())
        if(filters?.machines){
            filters.machines.map((item: FilterValues)=>{
                params.append("machine", item.key.toString())
            })           
        }
        filters?.organizations && filters.organizations.key && params.append("organization", filters.organizations.key.toString())
        filters?.dealership && filters.dealership.key && params.append("dealership", filters.dealership.key.toString())
        try{
            const data = await axiosMyJohnDeereInstance.get(
                API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING,
                {
                    params
                    
                }               
            )
            const status = errorHandling(data).request.status
            if(status === 200){
                callback()
                return data.data 
            }
        }catch(e){
            callback()
            console.log('Error: ' + e)
            notificationError()
        }
    } 
    
   return{
        getMaintenanceAlert
    }
}