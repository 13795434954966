import React, { } from 'react';
import "./style.scss"
import {Form, Select, Modal, Button, Input } from 'antd';
import { OrganizationModalProps } from './type';
import { useMaintenanceConfigurations } from '../../Services/useMaintenanceConfigurations';
import { useGetMachineSimplifiedEquipmentType } from '../../Services/useGetMachineSimplifiedEquipmentType';


export const MaintenanceConfigurationsModal: React.FC<OrganizationModalProps> = ({ 
  setOpenModalCreate,
  openModalCreate
})=>{

  const {createMantenanceConfigurations} = useMaintenanceConfigurations()
  const { Option } = Select;
  const { data: equipmentType } = useGetMachineSimplifiedEquipmentType()

  const [form] = Form.useForm(); 

  const handleCancel = () => {
    setOpenModalCreate(false);
    form.resetFields()
  };

  const handleSave = async(e:{
    equipment_type: string,
    maintenance_hours: string,
    range_hours: string,
    hours_before_alert: string
  })=>{
   
    let newMantenance: any = {
      equipment_type:  Number(e.equipment_type),
      maintenance_hours: Number(e.maintenance_hours),
      range_hours: Number(e.range_hours),
      hours_before_alert: Number(e.hours_before_alert)      
    }
    
    await createMantenanceConfigurations(newMantenance, handleCancel)   

  }

  return(
      <>
      <Modal 
        title='Crear tipo de mantenimiento' 
        open={openModalCreate}
        onCancel={handleCancel}
        footer={[
          <Button 
            key="cancel" 
            className='global_btn-modal-ghost'
            //loading={loading}
            onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="save"
            type="primary"
            className='global_btn-modal-primary'
            //loading={loadingSave}
            htmlType="submit"
            form='form-create'
          >
            Guardar
          </Button>,
        ]}
     >
        <div className='mantenanceModal_select modal'>
          <Form
            form={form}
            id='form-create'
            onFinish={handleSave}
           
          >
            <Form.Item
              name='equipment_type' 
              rules={[
                {
                  required: true,
                  message: 'Campo obligatorio',
                },
              ]}
            >
              <Select
                placeholder='Tipo de maquinaria'
              >
                {equipmentType &&
                  equipmentType.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                ))}
              
              </Select>
            </Form.Item>
            <Form.Item
              name='maintenance_hours' 
              rules={[
                {
                  required: true,
                  message: 'Campo obligatorio',
                },
              ]}
            >
              <Input
                placeholder='Horas para servicio de mantenimiento'
                type='number'
              />
            </Form.Item>
            <Form.Item
              name='range_hours' 
              rules={[
                {
                  required: true,
                  message: 'Campo obligatorio',
                },
              ]}
              
            >
              <Input
                placeholder='Rango de contemplación'
                type='number'
              />
            </Form.Item>
            <Form.Item
              name='hours_before_alert' 
              rules={[
                {
                  required: true,
                  message: 'Campo obligatorio',
                },
              ]}
            >
              <Input
                placeholder='Horas antes de emitir alerta'
                type='number'
              />
            </Form.Item>
          </Form>
        </div>          
      </Modal>
    </>)
}

