import React, {useContext, useEffect} from 'react';
import { Form, Button, DatePicker, Select, Tag, FormInstance, Input, Tooltip, Checkbox,  } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FilterValues } from '../../Services/useGetLeakedNotifications';
import './styles.scss'
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { useGetMachinesSimplifiedList } from '../../Services/useGetMachinesSimplifiedList';
import { useGetOrganizationsSimplifiedList } from '../../Services/useGetOrganizationsSimplifiedList';

const optionsColors = [
  { value: 'red', label: 'Rojo' }, 
  { value: 'blue', label:'Azul' }, 
  { value: 'gray', label:'Gris' }, 
  { value: 'yellow', label:'Amarillo'}
];

const tagRender = (props: CustomTagProps) => {

    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };    

    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };  

export interface NotificationFilters {
  colors: string[] | undefined,
  datePicker: Date[] | undefined,
  machines:  FilterValues[] | undefined,
  organizations: FilterValues[]| undefined,
  clave: string | undefined,
  observations: boolean,
  status: {
    value: string,
    label: string,
    key: string,
  }| undefined,
  note: boolean

}

export interface OrganizationsForSelector{
  value: string
  label: string
}

export interface AlertsFiltersProps{
  form:  FormInstance<any>,
  loadingTableData: boolean,
  handleGetPage: Function,
  handleSeeAll: ()=>void
}
export const AlertsFilters: React.FC<AlertsFiltersProps> =({
  form,
  loadingTableData,
  handleGetPage,
  handleSeeAll
})=>{

  const {setOrganizations, setDataLeakedNotifications} = useContext(BodyContext)
  const { data: machines } = useGetMachinesSimplifiedList()
  const {data: organizationsAll}= useGetOrganizationsSimplifiedList()
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const statusOptions = [
    { value: 'Sin atender', label: 'Sin atender', key: '0' }, 
    { value: 'Atendido', label:'Atendido', key: '1' }, 
    { value: 'Desatender', label:'Desatender', key: '2' }, 
    ];

  const typeStatus: { [key: string]: string } = {
      '0': 'Sin atender',
      '1': 'Atendido',
      '2': 'Desatender'
  };


  useEffect(()=>{
    if(organizationsAll){
      setOrganizations(organizationsAll)
    }
    
  }, [organizationsAll])

  const handleApplyFiltros = () =>{
    handleGetPage(undefined, true)
  }

  const tooltipText ={
    clave: "Utiliza este campo para filtrar la información buscando palabras clave específicas dentro de la descripción. Por ejemplo, puedes ingresar 'RC1 523501.01' como filtro para encontrar resultados relacionados."
  }
 
  return(
      <div className="alertsFilters_container">
          <p><div className='alertsFilters_point'> </div> FILTROS</p>
          <Form
            form={form}
            onFinish={handleApplyFiltros}  
          >
            <Form.Item 
              name='datePicker'
              className="alertsFilters_datePicker"
              >        
                <RangePicker  
                  style={{ width: '100%'}}
                  locale={locale}
                />
            </Form.Item>
            <Form.Item 
              name='colors'
              className="alertsFilters_selectColor">
                <Select
                    mode="multiple"
                    tagRender={tagRender}
                    style={{ width: '100%'}}
                    options={optionsColors}
                    placeholder="Tipo de notificación"
                />             
            </Form.Item >
            <Form.Item 
              name='machines'
              className="alertsFilters_selectOrganizations">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                labelInValue
                tokenSeparators={[',']}
                placeholder="Maquinaria"
              >
                 {machines &&
                machines.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select>  
            </Form.Item>
            <Form.Item
              name='organizations' 
              className="alertsFilters_selectOrganizations">
              <Select
                mode="multiple"
                placeholder= "Organización"
                labelInValue
                style={{ width: '100%' }}
                tokenSeparators={[',']}
              > 
              {organizationsAll &&
                organizationsAll.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select> 
            </Form.Item>
            <Form.Item
                name='status'
                className="alertsFilters_selectOrganizations"
            >
                  <Select
                    style={{ width: '100%' }}
                    labelInValue
                    placeholder="Estado"
                    allowClear
                >
                    {
                    statusOptions.map((item) => (
                        <Option value={item.value} key={item.key}>
                            {item.value}
                        </Option>
                    ))}
                
                </Select> 
            </Form.Item> 
            <Tooltip placement="right" title={tooltipText.clave} >
            <Form.Item
                name='clave'
                className="alertsFilters_selectOrganizations"
            >               
              <Input
                  style={{ width: '100%' }}
                  placeholder='Palabra clave'
              />                               
            </Form.Item> 
            </Tooltip>
            <Form.Item
                name='observations'
                className="alertsFilters_selectOrganizations"
                valuePropName="checked"
            >
              <Checkbox
               style={{ width: '100%' }}
              >
                Trabajos realizados
              </Checkbox>
            </Form.Item> 
            <Form.Item
                name='note'
                className="alertsFilters_selectOrganizations"
                valuePropName="checked"
            >
              <Checkbox
                  style={{ width: '100%' }}
              > 
                Notas
              </Checkbox>                 
            </Form.Item> 
            <Form.Item>
              <Button
                className="alertsFilters_filtersBtn"
                htmlType="submit"
                disabled={loadingTableData}
              >
                Aplicar
              </Button>
            </Form.Item>
          </Form>
          <Button
                className="alertsFilters_filtersBtn"
                onClick={()=>{handleSeeAll()}}                
                disabled={loadingTableData}
              >
                Ver todos
              </Button>
      </div>
    )
}

export default AlertsFilters;

