import { useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_POINT_MAINTENANCE_GET_ALL} from '../Axios/johnDeereEndPoinds'
import { useAuthSWR } from './useAuthSWR';

export interface Maintenance{
  id: number,
  is_active: boolean,
  engine_hours: string,
  comment: string,
  is_fake: boolean,
  date: string,
  machine: {
      id: number,
      organization: {
          id: number,
          name: string,
          type: string,
          organization_id: number,
      },
      is_active: boolean,
      type: string,
      visualization_category: string,
      category_name: string,
      category_id: string,
      make_name: string,
      make_id: string,
      model_name: string,
      model_id: string,
      detail_machine_code: string,
      product_key: string,
      engine_serial_number: string,
      engine_hours:string,
      telematics_state: string,
      GUID: string,
      model_year: string,
      machine_id: string,
      vin: string,
      name: string,
      eq_make_name: string,
      eq_make_ERID: string,
      eq_make_certified: boolean,
      eq_make_id: string,
      eq_type_name: string,
      eq_type_GUID: string,
      eq_type_category: string,
      eq_type_certified: boolean,
      eq_type_market_segment: string,
      eq_type_id: string,
      eq_apex_type_name: string,
      eq_apex_type_GUID: string,
      eq_apex_type_category: string,
      eq_apex_type_id: string,
      eq_model_name: string,
      eq_model_GUID: string,
      eq_model_certified: boolean,
      eq_model_classification: string,
      eq_model_id:string,
      is_serial_number_certified: string,
      dealership: dealership,
      equipment_type: number
  },
  user: {
      id: number,
      user: {
          username: string,
          email: string
      },
      firstname: string,
      lastname: string,
      is_active: boolean
  }
}

interface dealership{
  id: number,
  created: string, 
  updated: "2024-01-10T17:37:54.322919-03:00",
  address: string
  is_active: boolean,
  name: string,
}

export interface ResponseGetAllManntenance{
  count: number,
  current_page: number,
  page_count: number,
  next: string,
  previous_page: any,
  alerts_configuration: Maintenance[]
}

export const useGetAllMantenance = () => {

  const {data, error, mutate}:{
    data: ResponseGetAllManntenance, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_MAINTENANCE_GET_ALL])
    
  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate]);

  return {
    data,
    mutate,
  };
};
