import { useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_PONT_DEALERSHIP_GET_SIMPLIFIED_LIST} from '../Axios/johnDeereEndPoinds'
import {  Dealership } from '../Components/AlertsFilters/type';
import { useAuthSWR } from './useAuthSWR';


export const useGetDealershipSimplifiedList = () => {
    
    const {data, error, mutate }:{
      data: Dealership[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_PONT_DEALERSHIP_GET_SIMPLIFIED_LIST])

    
    useEffect(() => {
      if (!data && !error) {
        mutate();
      }
    }, [data, mutate]);
  
    return {
      data,
      mutate,
    };
};
