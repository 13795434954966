import { BrowserRouter,  Routes } from 'react-router-dom';
import { UserContextProvider } from './Context/UserContext/UsersContext';
import MainRutes from './Routes/Mainrutes/MainRutes';
import { BodyContextProvider } from './Context/BodyContext.tsx/BodyContext';
import { MachineryContextProvider } from './Context/MachineryContext.tsx/MachineryContext';
import { MaintenanceContextProvider } from './Context/ManitenanceContext/MaintenanceContext';

import './App.css';
import './assets/global.scss'
import { DealershipContext, DealershipContextProvider } from './Context/DealershipContext/DealershipContext';



const App = () => {

  return (
    <BrowserRouter>
     <UserContextProvider>
      <BodyContextProvider>
        <MachineryContextProvider>
          <MaintenanceContextProvider>
            <DealershipContextProvider>
              <MainRutes/>
            </DealershipContextProvider>
          </MaintenanceContextProvider>
        </MachineryContextProvider>
      </BodyContextProvider>
     </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;


