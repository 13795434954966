import {  useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_PONT_ORGANIZATIONS_GET_SIMPLIFIED_LIST} from '../Axios/johnDeereEndPoinds'
import { Organizations } from '../Components/AlertsFilters/type';
import { useAuthSWR } from './useAuthSWR';


export const useGetOrganizationsSimplifiedList = () => {

    const {data, error, mutate}:{
      data: Organizations[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_PONT_ORGANIZATIONS_GET_SIMPLIFIED_LIST])

    useEffect(() => {
      if (!data  && !error) {
        mutate();
      }
    }, [data, mutate]);
    
  return {
    data,
    mutate
  };
};
