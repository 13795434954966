const AUTH_END_POINT_POST_REGISTER = '/api/v1/auth/register';
const API_END_POINT_GET_USER = '/auth/';
const API_END_POINT_AUTH_BOGLICH_USERS = '/auth/boglich_users';
const AUTH_END_POINT_POST_LOGIN_CALLBACK = '/auth_johndeere/logincallback'; 
const AUTH_END_POINT_POST_USER_LOGIN = '/api/v1/auth/login'; 
const AUTH_END_POINT_PUT_USER = '/api/v1/geotoid/user';
const AUTH_END_POINT_GET_JD_CONNECT = '/auth_johndeere/login';
const API_END_POINT_MACHINE_ALERT_GET_ALL= '/machine_alert/get_all/';
const API_END_POINT_ORGANIZATIONS_GET_ALL = '/organization/get_all/';
const API_END_POINT_MACHINE_GET_ALL = '/machine/get_all/';
const API_END_POINT_MACHINE_GET = '/machine/get/';
const API_END_POINT_MACHINE_GET_BY_IDS = 'machine/get_by_ids/';
const API_END_POINT_DEALERSHIP_GET_ALL = '/dealership/get_all/';
const API_END_POINT_DEALERSHIP_CREATE = '/dealership/create/';
const API_END_POINT_DEALERSHIP_DELETE = '/dealership/delete/';
const API_END_POINT_DEALERSHIP_UPDATE = '/dealership/update/';
const API_END_POINT_MACHINE_POST_ASSOCIATE_DEALERSHIPS = 'machine/associate_dealerships/';
const API_END_POINT_MAINTENANCE_CREATE  ='/maintenance/create/'; 
const API_END_POINT_MAINTENANCE_UPDATE  ='/maintenance/update/';
const API_END_POINT_MAINTENANCE_DELETE  ='/maintenance/delete/';
const API_END_POINT_MAINTENANCE_GET_ALL  ='/maintenance/get_all/';
const API_END_POINT_MAINTENANCE_GET  ='/maintenance/get/';
const API_END_POINT_GET_ALL_EQUIPMENT_TYPES = '/machine/get_all_equipment_types/'
const API_END_POINT_MAINTENANCE_GET_ALL_ALERT  ='/maintenance/get_all_alerts/';
const API_END_POINT_MAINTENANCE_CREATE_ALERT  ='/maintenance/create_alert';
const API_END_POINT_MAINTENANCE_UPDATE_ALERT = '/maintenance/update_alert/';
const API_END_POINT_MAINTENANCE_DELETE_ALERT = '/maintenance/delete_alert/';
const API_END_POINT_MAINTENANCE_GET_ALERT  ='/maintenance/get_alert/'
const API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING = '/maintenance/get_all_upcoming/'
const API_END_PONT_MACHINE_GET_SIMPLIFIED_LIST ='/machine/get_simplified_list/'
const API_END_PONT_ORGANIZATIONS_GET_SIMPLIFIED_LIST ='/organization/get_simplified_list/'
const API_END_PONT_DEALERSHIP_GET_SIMPLIFIED_LIST ='/dealership/get_simplified_list/'
const API_END_PONT_MACHINE_GET_SIMPLIFIED_EQUIPMENT_TYPES ='/machine/get_simplified_equipment_types//get_simplified_equipment_types/'
const API_END_POINT_MACHINE_ALERT_UPDATE = '/machine_alert/update'

export {
    AUTH_END_POINT_POST_REGISTER,
    API_END_POINT_GET_USER,
    AUTH_END_POINT_POST_LOGIN_CALLBACK,  
    AUTH_END_POINT_POST_USER_LOGIN,
    AUTH_END_POINT_PUT_USER,
    AUTH_END_POINT_GET_JD_CONNECT  ,
    API_END_POINT_MACHINE_ALERT_GET_ALL,
    API_END_POINT_ORGANIZATIONS_GET_ALL,
    API_END_POINT_MACHINE_GET_ALL,
    API_END_POINT_MACHINE_GET,
    API_END_POINT_MACHINE_GET_BY_IDS,
    API_END_POINT_MACHINE_POST_ASSOCIATE_DEALERSHIPS,
    API_END_POINT_DEALERSHIP_DELETE,
    API_END_POINT_DEALERSHIP_UPDATE,
    API_END_POINT_DEALERSHIP_GET_ALL,
    API_END_POINT_AUTH_BOGLICH_USERS,
    API_END_POINT_MAINTENANCE_GET,
    API_END_POINT_MAINTENANCE_UPDATE,
    API_END_POINT_MAINTENANCE_GET_ALL,
    API_END_POINT_MAINTENANCE_CREATE,
    API_END_POINT_MAINTENANCE_DELETE,
    API_END_POINT_MAINTENANCE_GET_ALL_ALERT,
    API_END_POINT_MAINTENANCE_CREATE_ALERT,
    API_END_POINT_MAINTENANCE_UPDATE_ALERT,
    API_END_POINT_MAINTENANCE_DELETE_ALERT,
    API_END_POINT_DEALERSHIP_CREATE,
    API_END_POINT_MAINTENANCE_GET_ALERT,
    API_END_POINT_GET_ALL_EQUIPMENT_TYPES,
    API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING,
    API_END_PONT_MACHINE_GET_SIMPLIFIED_LIST,
    API_END_PONT_ORGANIZATIONS_GET_SIMPLIFIED_LIST,
    API_END_PONT_DEALERSHIP_GET_SIMPLIFIED_LIST,
    API_END_PONT_MACHINE_GET_SIMPLIFIED_EQUIPMENT_TYPES,
    API_END_POINT_MACHINE_ALERT_UPDATE
}