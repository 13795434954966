import { useEffect } from 'react';
import { KeyedMutator } from "swr";
import {API_END_POINT_GET_ALL_EQUIPMENT_TYPES} from '../Axios/johnDeereEndPoinds'
import {  Dealership } from '../Components/AlertsFilters/type';
import { useAuthSWR } from './useAuthSWR';

export interface ResponseGetDealership{
  count: number,
  next: string,
  previous: any
  results: Dealership[]
}

export const useGetEquipamentsTypes = () => {

  const {data, error, mutate}:{
    data: ResponseGetDealership, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_GET_ALL_EQUIPMENT_TYPES])

  useEffect(() => {
    if (!data  && !error) {
      mutate();
    }
  }, [data, mutate]);
  
    return {
      data,
      mutate,
    };
};
