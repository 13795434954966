import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { UserContext } from '../Context/UserContext/UsersContext';
import { axiosMyJohnDeereInstance } from '../Axios';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';


export const useErrorHandling =() => {

  const {setMyJohnDeereToken} = useContext(UserContext)

  const errorHandling = (data: AxiosResponse<any, any>) =>{
    if(data.request.status === 200){                       
        return data   
    }else if(data.status === 401){
        setMyJohnDeereToken(undefined)
        notification.error({
          message: 'Error', 
          description:
            'Sesión vencida, vuelva a loguearse', 
          placement: 'top'
        })
        return data
    }else{
        let error = data.request?.error ? data.request.error :  'Hubo un error, por favor comuníquese con el administrador'
        notification.error({
            message: 'Error', 
            description: error, 
            placement: 'topRight'
        })
        console.log(data.request?.error)
        return data
    } 
}

  return {
    errorHandling
  };
}
