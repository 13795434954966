import React, { useContext } from 'react'
import { axiosMyJohnDeereInstance } from '../Axios';
import{API_END_POINT_MACHINE_POST_ASSOCIATE_DEALERSHIPS} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { MachineryContext } from '../Context/MachineryContext.tsx/MachineryContext';
import { useGetMachinesSimplifiedList } from './useGetMachinesSimplifiedList';
import { useErrorHandling } from './useErrorHandling';
import { useGetAllMantenanceAlert } from './useGetAllMantenanceAlert';

export const useAssociateDealerships =  ()=>{

    const {myJohnDeereToken} = useContext(UserContext);
    const {
        setloadingBtnSave,
    } = useContext(MachineryContext)
    const { mutate } = useGetMachinesSimplifiedList()
    const {mutate: mutateAllMaintenance} = useGetAllMantenanceAlert()

    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }
 


    const createAssociation = async(assignmentList: any, filters: boolean)=>{
        try{
           const data = await axiosMyJohnDeereInstance.patch(
                API_END_POINT_MACHINE_POST_ASSOCIATE_DEALERSHIPS,
                assignmentList,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Asignación realizada con éxito', 
                    placement: 'topRight'
                })                   
                const status = errorHandling(data).request.status
                if(status === 200){
                    !filters && mutate() // si los filtros están cargados, recarga solo las maquinarias de los filtros, sino le pega a todos
                    setTimeout(()=>{
                        mutateAllMaintenance()
                    } , 5000)
                    setloadingBtnSave(false)
                    return 'ok'    
                }                    
            }   
        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setloadingBtnSave(false)
        } 
    }    
   return{
    createAssociation,
    }
}