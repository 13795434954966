import React, {  } from "react";

import './startupBackground.scss';

export const StartupBackground = ()=>{

    return(
      <div className='startupBackground-container'>
          <div className='startupBackground-opacityLayer'></div>
          <div className='startupBackground-containerImg'>
          </div>
      </div>       
    )
}

export default StartupBackground;