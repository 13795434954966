import BackgroundLoading from './BackgroundLoading';

import './animationLoading.scss'

export  const AnimationLoading =()=>{
    return(
        <div>
            <div className="container-loading">
               <div className='loading'>
                 <span>C</span>
                 <span>a</span>
                 <span>r</span>
                 <span>g</span>
                 <span>a</span>
                 <span>n</span>
                 <span>d</span>
                 <span>o</span>
                </div>
            </div>
            <BackgroundLoading/>
        </div>
      
      
    )
}

export default AnimationLoading;