import { KeyedMutator } from "swr";
import { axiosMyJohnDeereInstance } from '../Axios';
import {API_END_POINT_MACHINE_ALERT_GET_ALL} from '../Axios/johnDeereEndPoinds'
import { useAuthSWR } from "./useAuthSWR";
import { useContext, useEffect } from "react";
import { BodyContext } from "../Context/BodyContext.tsx/BodyContext";
import { FilterValues } from "./useGetLeakedNotifications";
import moment from "moment";


const fetcher = async (
  path: string, 
  token: string | undefined,
  ): Promise<any[]> => {

  try {
    const {
      data,
    }: { data: any } = await axiosMyJohnDeereInstance.get(
        path,
        {
          headers: {
            Authorization: 'JWT ' + token,
          },
        }
    );
    return data.results;
  } catch (newError) {
    console.log(newError);
    throw newError;
  }
};

export interface AlertDefinition {
  bus: any,
  created: Date,
  definition_id: string,
  description: string,
  failure_mode_indicator: any,
  id: number,
  is_active: boolean,
  source_address: any,
  suspect_parameter_name: any,
  three_letter_acronym: any,
  type: string,
  updated: Date
}

export interface Organizations{
  account_id: number,
  account_key: number,
  created: Date,
  id: number,
  internal: boolean,
  is_active: boolean,
  member: boolean,
  name: string,
  organization_id: string,
  type: string,
  updated: Date
}

export interface EquipmentType{
  GUID: string,
  category: string,
  certified: boolean,
  created: Date,
  id: number,
  is_active: true,
  market_segment: string,
  name: string,
  type_id: string,
  updated: Date
}

export interface Machine{
  GUID: string,
  category_id: string,
  category_name: string,
  created: Date,
  dealership: any,
  detail_machine_code: string,
  engine_hours: string,
  engine_serial_number: string,
  eq_apex_type_GUID: string,
  eq_apex_type_category: string,
  eq_apex_type_id: string,
  eq_apex_type_name: string,
  eq_make_ERID: string,
  eq_make_certified: true,
  eq_make_id: string,
  eq_make_name: string,
  eq_model_GUID: string,
  eq_model_certified: true,
  eq_model_classification: string,
  eq_model_id: string,
  eq_model_name: string,
  eq_type_GUID: string,
  eq_type_category: string,
  eq_type_certified: true,
  eq_type_id: string,
  eq_type_market_segment: string,
  eq_type_name: string,
  equipment_type: EquipmentType,
  id: 25,
  is_active: false,
  is_serial_number_certified: true,
  machine_id: string,
  make_id: string,
  make_name: string,
  model_id: string,
  model_name: string,
  model_year: string,
  name: string,
  organization: Organizations,
  product_key: string,
  telematics_state: string,
  type: string,
  updated: Date,
  vin: string,
  visualization_category: string
}

export interface Notifications{
  acknowledgement_status: any,
  alert_definition: AlertDefinition, 
  alert_id: string,
  bus: any,
  color: string,
  created: Date, //string
  duration_in_seconds: number,
  engine_hours: number,
  three_letter_acronym: string,
  failure_mode_indicator: number,
  suspect_parameter_name: number,
  note: string,
  observations: string,
  status: number; //0: sin atender - 1: atendido - 2: desatender
  id: number,
  ignored: boolean,
  invisible: boolean,
  is_active: boolean,
  location_lat: number,
  location_lon: number,
  machine: Machine,
  machine_linear_time: string,
  maintenance_interval_quantity: any,
  maintenance_interval_type: any,
  maintenance_interval_unit: any,
  occurrences: any,
  reported_interval: any,
  severity: string,
  time: Date,
  type: string,
  updated: Date
}

export interface ResponseGetNotifications{
  count: number,
  current_page: number,
  next: string,
  previous: string
  alerts: Notifications[]
}

export interface DataAlertsFiltersPorops{
  page: number | undefined,
  colors: string[] | undefined,
  datePicker: Date[] | undefined,
  machines:  FilterValues[] | undefined,
  organizations: FilterValues[]| undefined,
  clave: string | undefined,
  observations: boolean,
  status: {
    value: string,
    label: string,
    key: string,
  }| undefined,
  note: boolean
}
export const useGetNotifications = (
  ) => {
    const {contextDataAlers} = useContext(BodyContext)
    var params = new URLSearchParams();
     
    if(contextDataAlers){
      let day: any =   contextDataAlers.datePicker
      const startDate = contextDataAlers.datePicker && moment(day[0].$d).format('YYYY-MM-DD')
      const endDate = contextDataAlers.datePicker && moment(day[1].$d).format('YYYY-MM-DD')
      startDate && params.append("startDate", startDate)
      endDate && params.append("endDate", endDate)
     
      contextDataAlers.page && params.append("page", contextDataAlers.page.toString())
      contextDataAlers.status && contextDataAlers.status.key && params.append("status", contextDataAlers.status.key)
      contextDataAlers.clave && params.append("alertCode", contextDataAlers.clave)
      contextDataAlers.observations && params.append("observations", contextDataAlers.observations.toString())
      contextDataAlers.note && params.append("notes", contextDataAlers.note.toString())
    
      contextDataAlers.colors?.map((color: string)=>{
        params.append("color", color);
      })
      contextDataAlers.organizations?.map((organization: FilterValues)=>{
        let organizationString = organization.key.toString()
        params.append("organization", organizationString);
      })
      contextDataAlers.machines?.map((machine: FilterValues)=>{
        let machineString = machine.key.toString()
        params.append("machine", machineString);
      })
     
    } 

    const {data, error, mutate}:{
      data: ResponseGetNotifications, 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_POINT_MACHINE_ALERT_GET_ALL], params)

    useEffect(() => {
      if (!data  && !error) {
        mutate();
      }
    }, [data, mutate]);

  return {
    data,
    mutate,
  };
};
