import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import  { Button, Form, Table } from  'antd';
import {   ResponseGetAllTypeMAintenance, useGetAllTypeMaintenance } from '../../Services/useGetAllTypeMaintenance';
import { MaintenanceConfigurationsModal } from '../MaintenanceConfigurationsModal/MaintenanceConfigurationsModal';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { ToolOutlined  } from '@ant-design/icons';
import { ColumnsType, TableProps } from 'antd/es/table';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import MaintenanceconfigurationsEditModal from '../MaintenanceConfigurationsEditModal/MaintenanceConfigurationEditModal';
import { useGetEquipamentsTypes } from '../../Services/useGetEquipamentsTypes';
import {  useMaintenanceConfigurations } from '../../Services/useMaintenanceConfigurations';
import { MaintenanceConfigurationsfiltersType } from '../MaintenanceConfigurationsFilters/type';
import MaintenanceConfigurationsFilters from '../MaintenanceConfigurationsFilters/MaintenanceConfigurationsFilters';

interface RowData {
    equipment_type: string,
    hours_before_alert: string,
    id: number,
    maintenance_hours: string,
    range_hours: string,
}

export const MaintenanceConfigurations: React.FC =()=>{

    const { data: typeMaintenanceAll } = useGetAllTypeMaintenance()
    const { data: equipmentType } = useGetEquipamentsTypes()
    const { getMaintenanceConfigurations } = useMaintenanceConfigurations()
    const [form_maintenanceConfigurationsFilters] = Form.useForm();
    const {
        setSelectedMaintenanceconfigurations,
        openEditMaintenanceconfiguration, 
        setOpenEditMaintenanceConfiguration 
    } = useContext(MaintenanceContext)

    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [tableInformation, setTableInformation] = useState<RowData[]>()
    const [loadingTable, setLoadingTable] = useState<boolean>(true)
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10, // Número de elementos por página
        total: 0, // Número total de elementos
      });

    useEffect(()=>{
        if(typeMaintenanceAll){
            rowData()
            setLoadingTable(false)      
        }        
    },[typeMaintenanceAll])

    // useEffect(()=>{
    //     if(updateMaintenanceAlert){
    //         handleGetPage(requestPage, true)  
    //         setUpdateMaintenanceAlert(false)
    //     }        
    // },[updateMaintenanceAlert])   

    const rowData= (newData?: ResponseGetAllTypeMAintenance)=>{
        let data: RowData[]=[];
        let dataToLoadIntoTable: ResponseGetAllTypeMAintenance = newData ? newData : typeMaintenanceAll       
        dataToLoadIntoTable?.alerts_configuration.map((maintenance)=>{
            data.push({
                equipment_type: maintenance.equipment_type.name,
                hours_before_alert: maintenance.hours_before_alert.toFixed(),
                id: maintenance.id,
                maintenance_hours: maintenance.maintenance_hours.toFixed(),
                range_hours: maintenance.range_hours.toFixed()
            })
        })
        
        setPagination({
            ...pagination,
            page: dataToLoadIntoTable.current_page,
            pageSize: 50,
            total: dataToLoadIntoTable.count,
          });
          setTableInformation(data)
    }

    const handlePageChange: TableProps<RowData>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
      ) => {
        // Actualizar la página actual y la cantidad de elementos por página
        let page = pagination.current;
        let pageSize = pagination.pageSize;
        if (page && pageSize) {
          setPagination({
            ...pagination,
            page: page,
            pageSize: pageSize,
            total: 0,
          });
        }
      };    

    const handleRenderer = (text: string, event: RowData,  ) =>{
        return <a>{text}</a>
    }

    const machineryfilters = ()=>{
        let textAndValue: {text: string, value: string}[] =[]
        equipmentType?.results.map((item)=>{
            textAndValue.push({
                text: item.name,
                value:  item.name
            })
        })
        return textAndValue
    }

    const columns: ColumnsType<RowData> = [
        {
            title: 'Maquinaria',
            dataIndex: 'equipment_type',
            key: 'id',
            render: handleRenderer,
            // filters: machineryfilters(),
            // onFilter: (value, record) => typeof value === 'string' && record.equipment_type.includes(value),
            ellipsis: true
        },
        {
            title: 'Servicio',
            dataIndex: 'maintenance_hours',
            key: 'id',
            render: (text: number)=> <a>{text}hs</a>,
            ellipsis: true
        },
        {
            title: 'Rango de contemplación',
            dataIndex: 'range_hours',
            key: 'id',
            render: (text: number)=> <a>{text}hs</a>,
            ellipsis: true
        },
        {
            title: 'Horas antes de emitir alerta',
            dataIndex: 'hours_before_alert',
            key: 'id',
            render: (text: number)=> <a>{text}hs</a>,
            ellipsis: true
        },
    ];
  

    const onClickRow = (record: RowData) =>{
        setOpenEditMaintenanceConfiguration(true)
        setSelectedMaintenanceconfigurations({
            id: record.id,
            name: record.equipment_type,
            hours_before_alert: record.hours_before_alert,
            maintenance_hours: record.maintenance_hours,
            range_hours: record.range_hours,
        })
    }

    const callback = () =>{
        setLoadingTable(false)
    }

    const handleGetPage = async (page: number | undefined, loadingTable?: boolean) => {
        let values = form_maintenanceConfigurationsFilters.getFieldsValue() 
        let filters: MaintenanceConfigurationsfiltersType = values ? values : undefined 
        let pageNumber: number = page ? page : 1
        loadingTable && setLoadingTable(true)
        const data = await getMaintenanceConfigurations(pageNumber, callback, filters);
        if (data) {
          rowData(data);
          setPagination({
            ...pagination,
            page: data.current_page,
            pageSize: 50,
            total: data.count,
          });
        }
      };

      const handleSeeAll = () =>{
        if(typeMaintenanceAll){
            rowData(typeMaintenanceAll)
            form_maintenanceConfigurationsFilters.resetFields()
        }
      }  

    return(
        <div className="maintenanceConfigurations_container">
            <div className='maintenanceConfigurations_title_container'>
                <ToolOutlined style={{ fontSize: '18px', color: 'white', marginLeft:'10px'}} />
                <p>CONFIGURACIÓN DE MANTENIMIENTOS</p>
            </div>
            <div className='maintenanceConfigurations_body_container'>
                <div className='maintenanceConfigurations_submenu'>
                    <div className='maintenanceConfigurations_subtitle'>
                        <p><div className='maintenanceConfigurations_point'> </div> MENU</p>
                    </div>
                    <Button
                        className="global_btn-ghost"
                        htmlType="submit"
                        onClick={()=>{
                            setOpenModalCreate(true)
                        }}
                    >
                        Crear Mantenimiento
                    </Button>

                    <MaintenanceConfigurationsFilters
                         form_maintenanceConfigurationsFilters={form_maintenanceConfigurationsFilters}
                         handleGetPage={handleGetPage}
                         loadingTable={loadingTable}
                         handleSeeAll={handleSeeAll}
                    />
                </div>
                <div className='maintenanceConfigurations_timeline'>
                    {  loadingTable 
                        ? <SpinerHorizontal/>
                        : <Table 
                            columns={columns} 
                            dataSource={tableInformation}  
                            pagination={{
                                current: pagination.page,
                                pageSize: 50,
                                total: pagination.total,
                                onChange: (e)=>{handleGetPage(e, true)},
                                showSizeChanger: false,
                            }}
                            scroll={{ y: 600 }}
                            onChange={handlePageChange}
                            onRow={(record, index) => {
                                return {
                                    onClick: (event) => {
                                    onClickRow(record)
                                    }
                                };
                            }}
                        />
                    }
                </div>  
                {openEditMaintenanceconfiguration && <MaintenanceconfigurationsEditModal/> }
                {openModalCreate && <MaintenanceConfigurationsModal setOpenModalCreate={setOpenModalCreate} openModalCreate={openModalCreate}/>}
            </div>
        </div>
    )
}

export default MaintenanceConfigurations;

