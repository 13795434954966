import React, { useContext, useEffect,  useState } from 'react';
import  { Table, Form } from  'antd';
import { SoundOutlined} from '@ant-design/icons';
import './styles.scss'
import { MaintenanceModal } from '../MaintenanceModal/MaintenanceModal';
import { ResponseGetMaintenanceAlert, useGetAllMantenanceAlert } from '../../Services/useGetAllMantenanceAlert';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { WarningOutlined, FireOutlined, CheckOutlined} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import type {  TableProps } from 'antd/es/table';
import { useMaintenanceAlerts } from '../../Services/useMaintenanceAlerts';
import MaintenanceAlertFilters, { MainenanceAlertsFilters } from '../MaintenanceAlertFilters/MaintenanceAlertFilters';
import { MachineryContext } from '../../Context/MachineryContext.tsx/MachineryContext';

export interface DataType {
    id: number;
    machine_name: string;
    machine_id: number;
    equipment_type_name: string;
    dealership_name: string;
    vin: string;
    maintenance_hours: number;
    machine_engine_hours: number;
    hours_to_maintenance: number;
    color: string;
    organization_name: string;
    organization_id: number  
}

export const MaintenanceAlert: React.FC =()=>{

  const {data: allMaintenanceAlerts, mutate} = useGetAllMantenanceAlert()
  const { getMaintenanceAlert } = useMaintenanceAlerts()
  const {
    setDataMaintenanceAlert,
  } = useContext(MachineryContext)

  const {
    setSelectedMaintenanceAlert,  
    openMachineMaintenanceAlert, 
    setOpenMachineMaintenanceAlert
  } = useContext(MaintenanceContext) 

  const [form_maintenance] = Form.useForm();
  const [dataMaintenance, setDataMaintenance] = useState<DataType[]>([])
  const [loadingTable, setLoadingTable] = useState<boolean>(true)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 50, // Número de elementos por página
    total: 0, // Número total de elementos
  });

  type DataIndex = keyof DataType;


  useEffect(() => {
    const intervalId = setInterval(() => {
      mutate()
    }, 11 * 60 * 1000); 
    return () => clearInterval(intervalId);
  }, []); 

  useEffect(()=>{
      if(allMaintenanceAlerts){
        rowData()      
        setLoadingTable(false)
      }
  },[allMaintenanceAlerts])

  
    const rowData = (data? : ResponseGetMaintenanceAlert) =>{
      let filterData: DataType[]= []
      let list: ResponseGetMaintenanceAlert = data ? data : allMaintenanceAlerts
      let values = form_maintenance.getFieldsValue() 
     
      if(list && list.alerts){
        list.alerts.map((data, index)=>{
          let dealershipName = data.machine.dealership && data.machine.dealership.name ?  data.machine.dealership.name : ''      
          filterData.push({
              id: index,
              machine_name: data.machine.name,
              machine_id: data.machine.id,
              equipment_type_name: data.alert_configuration.equipment_type.name,
              dealership_name:  dealershipName,
              vin: data.machine.vin,
              maintenance_hours: data.alert_configuration.maintenance_hours,
              machine_engine_hours: Number(Number(data.machine.engine_hours).toFixed(2)),
              hours_to_maintenance: data.hours_to_maintenance,
              color: data.color,
              organization_name: data.machine.organization.name,
              organization_id: data.machine.organization.id
          })
        })
        setPagination({
          ...pagination,
          page: list.current_page,
          pageSize: 50,
          total: list.count,
        });
        //Cargo los filtos en una variable para que el get de alertas traiga las alertas con los filtros si es que tiene
        setDataMaintenanceAlert({
          page:  list.current_page,
          machines: values.machines,
          organizations: values.organizations,
          dealership: values.dealership
        })
      }       
      setDataMaintenance(filterData)
    }

    const renderAlertIcons = (text: string, data: any) => {
        let icon
        switch(text){
            case 'RED':
                icon = <FireOutlined style={{color: 'red'}}/>
                break;
            case 'YELLOW':
                icon = <WarningOutlined  style={{color: 'yellow'}}/>
                break;
            case 'BLUE':
                icon = <CheckOutlined style={{color: '#7ccf00'}}/>
                break;
            default:
                icon = <CheckOutlined style={{color: '#70b10e;'}}/>
        }
        return <div>
            {icon}
        </div> 
    }

    const handelRender = (text: string, data: any) => {
        return <a     
            onClick={()=>{                                
            }}
        >{text} hs</a> 
    }

    const onClickRow = (record: DataType) =>{
        setSelectedMaintenanceAlert(record)
        setOpenMachineMaintenanceAlert(true)  
    }  

    const handlePageChange: TableProps<DataType>['onChange'] = (
      pagination,
      filters,
      sorter,
      extra
    ) => {
      // Actualizar la página actual y la cantidad de elementos por página
      let page = pagination.current;
      let pageSize = pagination.pageSize;
      if (page && pageSize) {
        setPagination({
          ...pagination,
          page: page,
          pageSize: pageSize,
          total: 0,
        });
      }
    };

    const callback = () =>{
      setLoadingTable(false)
    }

    const handleGetPage = async (page?: number) => {
      let values = form_maintenance.getFieldsValue() 
      let filters: MainenanceAlertsFilters = values ? values : undefined 
      let pageNumber: number = page ? page : 1  
      setLoadingTable(true)
      const data = await getMaintenanceAlert(pageNumber, callback, filters);
      if (data) {
        rowData(data);
        setPagination({
          ...pagination,
          page: data.current_page,
          pageSize: 50,
          total: data.count,
        });          
      }
    };

    

    const columns: ColumnsType<DataType> = [
        {
            title: 'Alerta',
            dataIndex: 'color',
            width: '120px',
            key: 'color',
            render: renderAlertIcons,  
            ellipsis: true
        },
        {
            title: 'Maquinaria',
            dataIndex: 'machine_name',
            key: 'machine_name',
            render: (text)=><a>{text}</a>,
            ellipsis: true
        },
        {
          title: 'Organización',
          dataIndex: 'organization_name',
          key: 'organization_id',
          render: (text)=><a>{text}</a>,
          ellipsis: true
        },
        {
          title: 'Sucursal',
          dataIndex: 'dealership_name',
          key: 'dealership_name',
          render: (text)=><a>{text ? text : 'Sin asignación'}</a>,
          ellipsis: true
        },
        {
            title: 'Tipo de maquinaria',
            dataIndex: 'equipment_type_name',
            key: 'equipment_type_name',
            render: (text)=><a>{text}</a>,
            ellipsis: true               
        },
        
        // {
        //   title: 'Vin',
        //   dataIndex: 'vin',
        //   key: 'vin',
        //   render: (text)=><a>{text}</a>,
        //   onFilter: (value, record) => typeof value === 'string' && record.vin.includes(value),
        //   filterSearch: true,
        //   ellipsis: true
        // },
        {
          title: 'Mantenimiento a realizar',
          dataIndex: 'maintenance_hours',
          key: 'maintenance_hours',
          render: handelRender,
   //       sorter: (a, b) => a.maintenance_hours - b.maintenance_hours,
          ellipsis: true
        },
        {
          title: 'Horas de Motor',
          dataIndex: 'machine_engine_hours',
          key: 'id',
          ellipsis: true,
          render: (text: number, data: DataType)=> <a>{text} hs</a>,
          //sorter: (a, b) => a.machine_engine_hours - b.machine_engine_hours,  
        },
    ];

    
    const handleSeeAll =()=>{
        form_maintenance.resetFields()
        setDataMaintenanceAlert(undefined)
        handleGetPage()     
    }
 
    return(
        <div className="maintenance_container">
            <div className='maintenance_title_container'>
                <SoundOutlined style={{ fontSize: '18px', color: 'white', marginLeft:'10px'}} />
                <p>ALERTAS DE MANTENIMIENTO</p>
            </div>
            <div className='maintenance_body_container'>
                <div className='maintenance_submenu'>
                    <div className='maintenance_subtitle'>
                        <p><div className='maintenance_point'> </div> MENU</p>
                    </div>
                    <MaintenanceAlertFilters 
                      form_maintenance={form_maintenance}
                      handleGetPage={handleGetPage}
                      loadingTable={loadingTable}
                      handleSeeAll={handleSeeAll}
                    />
                </div>
                <div className='maintenance_timeline'> 
                 {loadingTable 
                  ?  <SpinerHorizontal/> 
                  :  <Table 
                        columns={columns} 
                        dataSource={dataMaintenance}  
                        pagination={{
                          current: pagination.page,
                          pageSize: 50,
                          total: pagination.total,
                          onChange: handleGetPage,
                          showSizeChanger: false,
                        }}
                        onChange={handlePageChange}
                        onRow={(record, index) => {
                          return {
                            onClick: (event) => {
                              onClickRow(record)
                            }
                          };
                        }}
                        scroll={{ y: 600 }}
                      />                     
                  }   
                </div>  
                
                {openMachineMaintenanceAlert && <MaintenanceModal  setOpenModalCreate={setOpenMachineMaintenanceAlert} openModalCreate={openMachineMaintenanceAlert}/>}

            </div>
        </div>
    )
}

export default MaintenanceAlert;

