import React, {  useContext } from 'react';
import {Form, Select, Modal, Button, Input } from 'antd';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import {  OrganizationModalProps } from './type';
import "./style.scss"
import { useMaintenance } from '../../Services/useMaintenance';
import { BoglichUsers, useGetBoglichUsers } from '../../Services/useGetBoglichUsers';
import { Machines } from '../AlertsFilters/type';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import { useGetMachinesSimplifiedList } from '../../Services/useGetMachinesSimplifiedList';

export const MaintenanceModal: React.FC<OrganizationModalProps> = ({ 
  setOpenModalCreate,
  openModalCreate
})=>{

  const {data: boglichUsers} = useGetBoglichUsers()
  const { createMantenance,  deleteMaintenance  } = useMaintenance()
  const { openMachineMaintenanceAlert, setOpenEditMaintenanceConfiguration, selectedMaintenanceAlert, setSelectedMaintenanceAlert } = useContext(MaintenanceContext)
  const {data: machines} = useGetMachinesSimplifiedList()
  const [form] = Form.useForm(); 
  const {Option} = Select
  const { TextArea } = Input;

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
   // console.log(date, dateString);
  };

  const handleCancel = () => {
    setOpenEditMaintenanceConfiguration(false)
    setOpenModalCreate(false);
    form.resetFields()
    setSelectedMaintenanceAlert(undefined)
  };

  const handleSave = async(e: any)=>{
    const date = e.date.format('YYYY-MM-DD')
    let newMantenance: any = {
      engine_hours: e.engine_hours,
      machine: e.machine.key,
      user: e.user,
      comment: e.comment,
      date: date,
    }
    await createMantenance(newMantenance, handleCancel )   
    form.resetFields()
    setSelectedMaintenanceAlert(undefined)
  }

  return(
      <>
      <Modal 
        title='Nuevo Mantenimiento' 
        open={openModalCreate || openMachineMaintenanceAlert}
        className='modal'
        onCancel={handleCancel}
        footer={[
          <Button 
            key="cancel" 
            //loading={loading}
            className='global_btn-modal-ghost'
            onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="save"
            type='primary'
            className='global_btn-modal-primary'
            //loading={loadingSave}
            htmlType="submit"
            form='form-create'
          >
            Guardar
          </Button>,
        ]}
     >
        <div className='mantenanceModal_select modal'>
          <Form
            form={form}
            id='form-create'
            layout="vertical"
            onFinish={handleSave}   
          >
            <Form.Item
              name='date' 
              rules={[
                {
                  required: true,
                  message: 'Debes elegir una fecha',
                },
              ]}
            >
               <DatePicker onChange={onChange} />
            </Form.Item>
            <Form.Item
              label= 'Maquinaria'
              name='machine' 
              initialValue={selectedMaintenanceAlert && {
                value: selectedMaintenanceAlert.machine_name,
                key: selectedMaintenanceAlert.machine_id,
                name: selectedMaintenanceAlert.machine_name
              }}
              rules={[
                {
                  required: true,
                  message: 'Debes elegir una Maquinaria',
                },
              ]}
            >
              <Select
                placeholder='Maquinaria'
                showSearch
                labelInValue
                defaultValue={selectedMaintenanceAlert && {
                  value: selectedMaintenanceAlert.machine_name,
                  key: selectedMaintenanceAlert.machine_id,
                  name: selectedMaintenanceAlert.machine_name
                }}
              >
                {machines &&
                    machines.map((machine: Machines) => (
                    <Option value={machine.name} key={machine.id}>
                        {machine.name}
                    </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='user' 
              label='Usuario'
              rules={[
                {
                  required: true,
                  message: 'Debes elegir un usuario',
                },
              ]}
            >
              <Select
                placeholder='Usuario'
              >
                {boglichUsers &&  
                    boglichUsers.map((boglichUser: BoglichUsers) => (
                    <Option value={boglichUser.id} key={boglichUser.id}>
                        {boglichUser.user.username}
                    </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='engine_hours' 
              label='Total horas'
              rules={[
                {
                  required: true,
                  message: 'Debes ingresar las horas del servicio',
                },
              ]}
            >
              <Input
                placeholder='Horas de motor'
              />
            </Form.Item>
            
            <Form.Item
              name='comment' 
            >
              <TextArea
                placeholder='Comentario'
              />
            </Form.Item>
          </Form>       
        </div>          
      </Modal>
    </>)
}

