import { defineMessages } from 'react-intl';

export const group = 'component.messagesGeneric';

export const messagesGeneric = defineMessages({
    contactAdministrator: {
      id: `${group}.contactAdministrator`,
      defaultMessage: "Ha ocurrido un error, por favor comuníquese con el administrador.",
    },
    completeFields: {
      id: `${group}.completeFields`,
      defaultMessage: "¡Debes completar todos los campos!",
    },
    return: {
      id: `${group}.return`,
      defaultMessage: "Volver",
    },
    modalError: {
      id: `${group}.modalError`,
      defaultMessage:  'Error',
    },
    
});

  

