import React, {
    createContext,
    useState,
  } from 'react';
import { SelectedMaintenance, SelectedMaintenanceCofigurations } from './types';
import { DataType } from '../../Components/MaintenanceAlert/MaintenanceAlert';

  class VoidContext implements MaintenanceContext {
    get openEditMaintenance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOpenEditMaintenance(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedMaintenance(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedMaintenance(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedMaintenanceConfiguration(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedMaintenanceconfigurations(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get openEditMaintenanceconfiguration(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOpenEditMaintenanceConfiguration(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedMaintenanceAlert(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedMaintenanceAlert(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get openMachineMaintenanceAlert(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOpenMachineMaintenanceAlert(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface MaintenanceContext {
    openEditMaintenance: boolean,
    setOpenEditMaintenance: Function,
    selectedMaintenance: SelectedMaintenance | undefined, 
    setSelectedMaintenance: Function,
    selectedMaintenanceConfiguration: SelectedMaintenanceCofigurations | undefined
    setSelectedMaintenanceconfigurations: Function,
    openEditMaintenanceconfiguration: boolean,
    setOpenEditMaintenanceConfiguration: Function,
    selectedMaintenanceAlert: DataType | undefined,
    setSelectedMaintenanceAlert: Function,
    openMachineMaintenanceAlert:boolean,
    setOpenMachineMaintenanceAlert: Function,
  }


  interface propsWithChildren {
    children: React.ReactNode
  } 

   
  export const MaintenanceContext = createContext<MaintenanceContext>(new VoidContext());
  export const MaintenanceContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [openEditMaintenance, setOpenEditMaintenance] = useState<boolean>(false)
    const [selectedMaintenance, setSelectedMaintenance] = useState<SelectedMaintenance | undefined>()
    const [selectedMaintenanceConfiguration, setSelectedMaintenanceconfigurations] = useState<SelectedMaintenanceCofigurations | undefined>()
    const [openEditMaintenanceconfiguration, setOpenEditMaintenanceConfiguration] = useState<boolean>(false)
    const [selectedMaintenanceAlert, setSelectedMaintenanceAlert] = useState< DataType | undefined>()
    const [openMachineMaintenanceAlert, setOpenMachineMaintenanceAlert] = useState<boolean>(false)
      
 
    return (
      <MaintenanceContext.Provider value={{
        openEditMaintenance, 
        setOpenEditMaintenance,
        selectedMaintenance,
        setSelectedMaintenance,
        selectedMaintenanceConfiguration, 
        setSelectedMaintenanceconfigurations,
        openEditMaintenanceconfiguration, 
        setOpenEditMaintenanceConfiguration,
        selectedMaintenanceAlert, 
        setSelectedMaintenanceAlert,
        openMachineMaintenanceAlert, 
        setOpenMachineMaintenanceAlert

      }}>{children}</MaintenanceContext.Provider>
    );
  };



  