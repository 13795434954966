import React, { useContext, useEffect, useState } from 'react';
import './styles.scss'
import { Button, Table, Form } from  'antd';
import { ToolOutlined,   } from '@ant-design/icons';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { MaintenanceModal } from '../MaintenanceModal/MaintenanceModal';
import {  ResponseGetAllManntenance, useGetAllMantenance } from '../../Services/useGetAllMantenance';
import { ColumnsType, TableProps } from 'antd/es/table';
import MaintenanceEditModal from '../MaintenanceEditModal/MaintenanceEditModal';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import { MachineryContext } from '../../Context/MachineryContext.tsx/MachineryContext';
import { useMaintenanceCarriedOut } from '../../Services/useMaintenanceCarriedOut';
import { MaintenanceCarriedOutFiltersType } from '../MaintenanceCarriedOutFilters/type';
import MaintenanceCarriedOutFilters from '../MaintenanceCarriedOutFilters/MaintenanceCarriedOutFilters';


interface DataType {
    key: number;
    machineryName: string;
    user: string;
    maintenance: string;
    comment: string;
    date: string
    dealership_name: string;
    dealership_id: string;
    organization_name: string;
    organization_id: number  
  }

export const MaintenanceCarriedOut: React.FC =()=>{

    const {data: allMaintenance} = useGetAllMantenance()
    const {getMaintenanceCarriedOut} = useMaintenanceCarriedOut()
    const {         
        setSelectedMaintenance, 
        openEditMaintenance, 
        setOpenEditMaintenance,
    } = useContext(MaintenanceContext)

    const [openModalCreate, setOpenModalCreate] = useState<boolean>(false)
    const [dataMaintenance, setDataMaintenance] = useState<DataType[]>()
    const [loadingTable, setLoadingTable] = useState<boolean>(true)
    const [form_maintenanceCarriedOut] = Form.useForm();
    const [requestPage, setRequestPage] = useState<number>(1)
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 10, // Número de elementos por página
        total: 0, // Número total de elementos
      });
  
    useEffect(()=>{
        if(allMaintenance){
            rowData()
            setLoadingTable(false)            
        }
    },[allMaintenance])

    const rowData = (data?: ResponseGetAllManntenance)=>{
        let filterData: DataType[]= []
        let list: ResponseGetAllManntenance = data ? data : allMaintenance
        if(list){
            list?.alerts_configuration.map((data)=>{
              let dealershipName = data.machine.dealership && data.machine.dealership.name ?  data.machine.dealership.name : ''
              let dealershipId =  data.machine.dealership && data.machine.dealership.id ?  data.machine.dealership.name : ''
                filterData.push({
                    key: data.id,
                    machineryName: data.machine.name,
                    user: data.user.user.username,
                    maintenance: Number(data.engine_hours).toFixed(),
                    comment: data.comment,
                    date: data.date,
                    dealership_name:  dealershipName,
                    dealership_id: dealershipId,
                    organization_name: data.machine.organization.name,
                    organization_id: data.machine.organization.id
                })
            })
        }        
        setPagination({
            ...pagination,
            page: allMaintenance.current_page,
            pageSize: 10,
            total: allMaintenance.count,
        });        
        setDataMaintenance(filterData)
    }


    const handelRender = (text: string, key: any) => {
        return <a>{text}</a>    
    }

    const onClickRow = (record: DataType) =>{
        let selected  = allMaintenance?.alerts_configuration.filter((x)=>x.id == record.key)
        if(selected){
            setSelectedMaintenance({
                id: selected[0].id,
                engine_hours: selected[0].engine_hours,
                comment: selected[0].comment,
                date: selected[0].date,
                machine:{
                    id: selected[0].machine.id,
                    name: selected[0].machine.name
                },
                usuario:{
                    id: selected[0].user.id,
                    user: {
                        username: selected[0].user.user.username,
                        email: selected[0].user.user.email
                    },
                },
            })
            setOpenEditMaintenance(true)   
        }          
    }

    const machineryfilters = ()=>{
        let textAndValue: {text: string, value: string}[] =[]
        allMaintenance?.alerts_configuration.map((item)=>{
            textAndValue.push({
                text: item.machine.name,
                value:  item.machine.name
            })
        })
        return textAndValue
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Maquinaria',
            dataIndex: 'machineryName',
            key: 'machineryName',
            render: handelRender,
            filters: machineryfilters(),
            onFilter: (value, record) => typeof value === 'string' && record.machineryName.includes(value),
            filterSearch: true,
            ellipsis: true,
        },
        {
            title: 'Fecha de servicio',
            dataIndex: 'date',
            key: 'date',
            render: handelRender,
            sorter: (a, b) => 1,
            ellipsis: true,
            
        },
        {
          title: 'Organización',
          dataIndex: 'organization_name',
          key: 'organization_id',
          render: (text)=><a>{text}</a>,
          // filters: filtersByDealership(),
          // onFilter: (value, record) => typeof value === 'string' && record.dealership_name.includes(value),
          // filterSearch: true,
          ellipsis: true
        },
        {
          title: 'Sucursal',
          dataIndex: 'dealership_name',
          key: 'dealership_id',
          render: (text)=><a>{text ? text : 'Sin asignación'}</a>,
          // filters: filtersByDealership(),
          // onFilter: (value, record) => typeof value === 'string' && record.dealership_name.includes(value),
          // filterSearch: true,
          ellipsis: true
        },
        {
          title: 'Usuario',
          dataIndex: 'user',
          key: 'user',
          render: handelRender,
          ellipsis: true,
        },
        {
          title: 'Mantenimiento',
          dataIndex: 'maintenance',
          key: 'maintenance',
          render: (text)=><a>{text}hs</a>,
          ellipsis: true,
        },
        {
          title: 'Comentarios',
          key: 'comment',
          dataIndex: 'comment',
          ellipsis: true,
          render: handelRender
        },
    ];

    const callback = () =>{
        setLoadingTable(false)
      }

    const handleGetPage = async (page: number | undefined, loadingTable?: boolean) => {
        // let lastPage = pagination.total/10
        // let filter: string | undefined = form_manageUser.getFieldValue('filter');
        let values = form_maintenanceCarriedOut.getFieldsValue() 
        let filters: MaintenanceCarriedOutFiltersType = values ? values : undefined 
        let pageNumber: number = page ? page : 1
        setRequestPage(pageNumber)
        loadingTable && setLoadingTable(true)
        const data = await getMaintenanceCarriedOut(pageNumber, callback, filters);
        if (data) {
          rowData(data);
          setPagination({
            ...pagination,
            page: data.current_page,
            pageSize: 50,
            total: data.count,
          });
        }
      };
    
      const handlePageChange: TableProps<DataType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
      ) => {
        // Actualizar la página actual y la cantidad de elementos por página
        let page = pagination.current;
        let pageSize = pagination.pageSize;
        if (page && pageSize) {
          setPagination({
            ...pagination,
            page: page,
            pageSize: pageSize,
            total: 0,
          });
        }
      };

      const handleSeeAll =()=>{
        if(allMaintenance){
          rowData(allMaintenance)
          form_maintenanceCarriedOut.resetFields()
        }
      }
    

   
    return(
        <div className="mantenanceConfigurations_container">
            <div className='mantenanceConfigurations_title_container'>
                <ToolOutlined style={{ fontSize: '18px', color: 'white', marginLeft:'10px'}} />
                <p>MANTENIMIENTOS REALIZADOS</p>
            </div>
            <div className='mantenanceConfigurations_body_container'>
                <div className='mantenanceConfigurations_submenu'>
                    <div className='mantenanceConfigurations_subtitle'>
                        <p><div className='mantenanceConfigurations_point'> </div> MENU</p>
                    </div>
                   
                    <Button
                     //   type="dashed"
                        className="global_btn-ghost"
                        htmlType="submit"
                        onClick={()=>{
                            setOpenModalCreate(true)
                        }}
                    >
                        Nuevo Mantenimiento
                    </Button>

                    <MaintenanceCarriedOutFilters
                        form_maintenanceCarriedOut={form_maintenanceCarriedOut}
                        handleGetPage={handleGetPage}
                        loadingTable={loadingTable}
                        handleSeeAll={handleSeeAll}
                    />
                </div>
                <div className='mantenanceConfigurations_timeline'> 
                 { loadingTable 
                    ? <SpinerHorizontal/>  
                    : <Table 
                        columns={columns} 
                        dataSource={dataMaintenance}  
                        pagination={{
                            current: pagination.page,
                            pageSize: 50,
                            total: pagination.total,
                            onChange: (e)=>{handleGetPage(e, true)},
                            showSizeChanger: false,
                          }}
                        scroll={{ y: 600 }}
                        onChange={handlePageChange}
                        onRow={(record, index) => {
                            return {
                              onClick: (event) => {
                                onClickRow(record)
                              }
                            };
                          }}
                     />
                     
                    }         
                </div>  
                {openEditMaintenance && <MaintenanceEditModal handleGetPage={handleGetPage}/>}
                {openModalCreate && <MaintenanceModal setOpenModalCreate={setOpenModalCreate} openModalCreate={openModalCreate} />}

            </div>
        </div>
    )
}

export default MaintenanceCarriedOut;

