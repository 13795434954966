import React, {
    createContext,
    useState,
  } from 'react';
import { DataType } from '../../Components/Alerts/Alerts';
import { FilterValues } from '../../Services/useGetLeakedNotifications';
import { DataAlertsFiltersPorops } from '../../Services/useGetNotifications';

  class VoidContext implements BodyContext {
    get layerBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLayerBody(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get dataLeakedNotifications(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setDataLeakedNotifications(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get organizations (): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOrganizations(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get selectedAlertNotification(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setSelectedAlertNotification(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get openAlertModal(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setOpenAlertModal(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get fullScreen(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setFullScreen(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get contextDataAlers(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setContextDataAlert(): never {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  type LayerBody = | 'home' |'alert' | 'dealerships' | 'machinery' | 'configurations' | 'maintenance' | 'maintenanceAlert' | 'maintenanceConfigurations'


  interface BodyContext {
    layerBody: LayerBody,
    setLayerBody: Function,
    dataLeakedNotifications: any, 
    setDataLeakedNotifications: Function,
    organizations: Organizations[] | undefined
    setOrganizations: Function,
    selectedAlertNotification: DataType | undefined, 
    setSelectedAlertNotification: Function,
    openAlertModal: boolean,
    setOpenAlertModal: Function,
    fullScreen: boolean,
    setFullScreen: Function,
    contextDataAlers: DataAlertsFiltersPorops,
    setContextDataAlert: Function
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  interface Organizations{
    name: string
    id: number
  } 

  interface AlertNotificationFilters{
    page: number,  
    colors: string,
    startDate: string,
    endDate: string,
    machines: number,
    organizations: string,
  }
  
  export const BodyContext = createContext<BodyContext>(new VoidContext());
  export const BodyContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const [layerBody, setLayerBody] = useState<LayerBody>('alert')
    const [dataLeakedNotifications, setDataLeakedNotifications] = useState()
    const [organizations, setOrganizations] =useState<Organizations[] | undefined>()      
    const [selectedAlertNotification, setSelectedAlertNotification] = useState<DataType | undefined>()
    const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)   
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [contextDataAlers, setContextDataAlert] = useState<DataAlertsFiltersPorops>({
      page: undefined,
      colors:  undefined,
      datePicker:undefined,
      machines: undefined,
      organizations: undefined,
      clave: undefined,
      observations: false,
      status: undefined,
      note: false
    })
 
    return (
      <BodyContext.Provider value={{
        layerBody, 
        setLayerBody,
        dataLeakedNotifications,
        setDataLeakedNotifications,
        organizations, 
        setOrganizations,
        selectedAlertNotification,
        setSelectedAlertNotification,
        openAlertModal, 
        setOpenAlertModal,
        fullScreen,
        setFullScreen,
        contextDataAlers, 
        setContextDataAlert
      }}>{children}</BodyContext.Provider>
    );
  };



  