import {  useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_POINT_MAINTENANCE_GET_ALL_ALERT} from '../Axios/johnDeereEndPoinds'
import { useAuthSWR } from './useAuthSWR';

export interface EquipementsType{
  category: string,
  certified: boolean,
  GUID: string,
  id: number,
  is_active: boolean,
  market_segment: string,
  name: string,
  type_id: number,
}
export interface AllTypeMAintenance {
  equipment_type: EquipementsType,
  hours_before_alert: number,
  id: number,
  is_active: boolean,
  maintenance_hours: number,
  range_hours: number,
}

export interface ResponseGetAllTypeMAintenance{
  count: number,
  current_page: number,
  page_count: number,
  next: string,
  previous: any,
  alerts_configuration: AllTypeMAintenance[]
}

export const useGetAllTypeMaintenance = () => {

  
  const {data, error, mutate}:{
    data: ResponseGetAllTypeMAintenance, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_MAINTENANCE_GET_ALL_ALERT])

  useEffect(() => {
    if (!data  && !error) {
      mutate();
    }
  }, [data, mutate]);

  return {
    data,
    mutate,
  };
};
