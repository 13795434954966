import React, { useContext,  useState } from 'react';
import { Button, Modal, Input, Form, Select, Row, Col,   } from  'antd';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import { useMaintenanceConfigurations } from '../../Services/useMaintenanceConfigurations';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import TextArea from 'antd/es/input/TextArea';
import './style.scss'
import { useAlerts } from '../../Services/useAlerts';
import { AlertsModalProps } from './type';

export const AlertsModal: React.FC<AlertsModalProps> =({
    handleGetPage
})=>{

    const {
        selectedMaintenanceConfiguration, 
       
    } = useContext(MaintenanceContext)

    const{
        openAlertModal,
        setOpenAlertModal,
        setSelectedAlertNotification,
        selectedAlertNotification
    } = useContext(BodyContext)

    const {deleteMaintenanceConfigurations, editMaintenanceConfigurations} = useMaintenanceConfigurations()
    const {editAlert}  = useAlerts()
    const { Option } = Select;
    
    const [form_edit_alert] = Form.useForm();
    const [editDataAlert, setEditDataAlert] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [status_color] = useState(()=>{

        switch(selectedAlertNotification?.status){
            case '0':
                return 'red'
            case '1':
                return '#b6a701'
            case '2':
                return 'gray'
        }
    })

    const handleCancel =()=>{
        setOpenAlertModal(false)
        setSelectedAlertNotification(undefined)
        setEditDataAlert(false)
        form_edit_alert.resetFields()
    }

    const callback =()=>{
        handleCancel()
        setLoadingBtn(false)
        handleGetPage(1, false)

    }
    
    const handleSave = async (e:{
        status: {
            value: string,
            label: string,
            key: string
        },
        note: string,
        observations: string
    }) =>{
        if(selectedAlertNotification ){
            setLoadingBtn(true)
            let newData = {               
                status: Number(e.status.key),
                note: e.note,
                observations: e.observations
            }
            await editAlert( selectedAlertNotification?.id, newData, callback, 'Alerta')  
        }        
    }

    const DeletedModal = ()=>{
        return(
            <>
            <Modal   
                title='Eliminar' 
                open={openDeleteModal}
                className='modal buttons_modal'
                onCancel={()=>{
                    setOpenDeleteModal(false)
                    // setSelectedMaintenance(undefined)
                }}
                onOk={async()=>{
                    if(selectedMaintenanceConfiguration) await deleteMaintenanceConfigurations(selectedMaintenanceConfiguration.id, handleCancel)
                }}
            >
                <p>¿Está seguro que desea eliminar el tipo de mantenimiento de {selectedMaintenanceConfiguration?.name}?</p>
                
            </Modal>
            </>
        )
    }

    const statusOptions = [
        { value: 'Sin atender', label: 'Sin atender', key: '0' }, 
        { value: 'Atendido', label:'Atendido', key: '1' }, 
        { value: 'Desatender', label:'Desatender', key: '2' }, 
        ];

    const typeStatus: { [key: string]: string } = {
        '0': 'Sin atender',
        '1': 'Atendido',
        '2': 'Desatender'
    };

    return(
        <>
            <Modal   
                title={'Código de error: ' + selectedAlertNotification?.code} 
                open={openAlertModal}
                onCancel={handleCancel}
                className='modal h4_modal'
                width={1000}
                style={{ top: 15 }}
                footer={[
                    <Button 
                        key="edit" 
                        className='global_btn-modal-ghost'
                        onClick={()=>{                            
                            setEditDataAlert(!editDataAlert)
                            if(editDataAlert){

                            }
                        }}
                    >
                        {editDataAlert ? 'Cancelar edición' : 'Editar' }
                    </Button>,                   
                    <Button 
                        key="cancel" 
                        className='global_btn-modal-ghost'
                        //loading={loading}
                        onClick={handleCancel}>
                        Cancelar
                    </Button>, 
                    <Button 
                        key="save" 
                        htmlType="submit"
                        form='form_edit_alert'
                        disabled={!editDataAlert}
                        className='global_btn-modal-primary'
                    >
                      Guardar 
                 </Button>,        
                               
                ]}
            >
                <div className='alertModal_container'>
                    <div>
                        <Row
                            style={{
                                border:'1px solid rgb(240, 240, 240',
                                borderRadius: '5px',
                            }}
                        >
                            <Col span={6} style={{ textAlign: 'center',}}>
                                <h4>Organización</h4>
                                <p>{selectedAlertNotification?.organization_name}</p>
                            </Col>                               
                            <Col span={6} style={{ textAlign: 'center',}}>
                                <h4>Sucursal</h4>
                                <p>{selectedAlertNotification?.dealership_name || 'Sin asignación'}</p>
                            </Col>
                            <Col span={6} style={{ textAlign: 'center',}}>
                                <h4>Maquinaria</h4>
                                <p>{selectedAlertNotification?.machine_name}</p>
                            </Col>
                            <Col span={6} style={{ textAlign: 'center',}}> 
                                <h4>Tipo de maquinaria</h4>
                                <p>{selectedAlertNotification?.equipment_type_name}</p>
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row
                            style={{
                                border:'1px solid rgb(240, 240, 240',
                                borderRadius: '5px',
                                marginTop: '5px'
                            }}
                        >
                            <Col span={24} style={{ textAlign: 'center',}}>
                                <h4>Descripción de error</h4>
                                <p>{selectedAlertNotification?.description}</p>
                            </Col>                                                                                   
                        </Row>
                    </div>

                    {
                        editDataAlert 
                        ? 
                        <Form 
                            form={form_edit_alert}
                            id='form_edit_alert'
                            layout="vertical"
                            onFinish={handleSave}
                        >    
                            <Form.Item
                                style={{marginTop:'20px'}}
                                label='Estado:'
                                name='status'
                                initialValue={selectedAlertNotification && {
                                    value:  selectedAlertNotification.status_description,
                                    key: selectedAlertNotification.status,
                                    name: selectedAlertNotification.status_description
                                  }}
                            >
                                 <Select
                                    style={{ width: '100%' }}
                                    labelInValue
                                    placeholder="Estado"
                                    defaultValue={selectedAlertNotification && {
                                        value:  selectedAlertNotification.status_description,
                                        key: selectedAlertNotification.status,
                                        name: selectedAlertNotification.status_description
                                      }}
                                >
                                    {
                                    statusOptions.map((item) => (
                                        <Option value={item.value} key={item.key}>
                                            {item.value}
                                        </Option>
                                    ))}
                                
                                </Select> 
                            </Form.Item>                                
                            <Form.Item
                                label ='Trabajos realizados / Observaciones'
                                name ='observations' 
                                initialValue={selectedAlertNotification?.observation}
                            >
                                <TextArea
                                    rows={4} 
                                    placeholder='Escribe los trabajos realizados'
                                    defaultValue={selectedAlertNotification?.observation}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Nota'
                                name='note' 
                                initialValue={selectedAlertNotification?.note}
                            >
                               <TextArea
                                    rows={4} 
                                    placeholder='Escribe una nota'
                                    defaultValue={selectedAlertNotification?.note}
                                />                                
                            </Form.Item>
                        </Form> 
                        : 
                            <div style={{marginTop:'20px'}}>
                            <h4  >Estado:</h4>
                            <div className='alertModal_status'>
                                <p style={{
                                        color: status_color,
                                    }}
                                > {selectedAlertNotification?.status ? typeStatus[selectedAlertNotification.status] : ''}</p>
                               
                            </div>

                            <h4>Trabajos realizados / Observaciones:</h4>
                            <div className='alertModal_box alertModal_box_observations'>
                                <p>
                                     {selectedAlertNotification?.observation}
                                </p>
                               
                            </div>
                            <h4>Nota:</h4>
                            <div className='alertModal_box alertModal_box_note'>
                                <p>
                                    {selectedAlertNotification?.note}
                                </p>
                               
                            </div>
                            </div>
                           
                    }                    
                </div>            
            </Modal>
            {openDeleteModal && <DeletedModal/>}
            </>
        )
    }
  
export default AlertsModal;

