import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { UserContext } from '../Context/UserContext/UsersContext';
import { axiosMyJohnDeereInstance } from '../Axios';
import { notification } from 'antd';

const fetcher = async (path: string, params?: URLSearchParams): Promise<any> => {
  try {
    const {
      data,
    }: { data: any } = await axiosMyJohnDeereInstance.get(
        path,
        {params}
    );
    return data;
  } catch (newError) {
    console.log(newError);
    throw newError;
  }
};

export const useAuthSWR =(
  url: string[],
  params?:  URLSearchParams
) => {

  const {setMyJohnDeereToken} = useContext(UserContext)

  const { data, error, mutate } = useSWR(
    url, 
    ([url]) => fetcher(url, params ? params : undefined),
    {
      onErrorRetry(error, key, config, revalidate, { retryCount }) {
        if (error.response.status === 401 ) return;
        if (retryCount >= 3) return;
      },
      revalidateOnMount: false
    }  
  )

  useEffect(() => {
    if(error){
      if(error.response && error.response.status === 401){ // token vencido o no autorizado
        setMyJohnDeereToken(undefined)
        notification.error({
          message: 'Sesión', 
          description:
            'Sesión vencida, vuelva a loguearse', 
          placement: 'top'
        })
      }
    }else{
     // throw console.log(error);
    }
  }, [error]);

  return {
    data,
    error,
    mutate,
  };
}
