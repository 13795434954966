import { axiosMyJohnDeereInstance } from '../Axios';
import{API_END_POINT_MACHINE_GET, API_END_POINT_MACHINE_GET_BY_IDS} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { Machines } from '../Components/AlertsFilters/type';
import { useErrorHandling } from './useErrorHandling';

export const useMachine =  ()=>{

    const {errorHandling} = useErrorHandling()

    const getMachine = async(id: number, setLoadingSearch: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.get(
            API_END_POINT_MACHINE_GET + id,
            )
            const status = errorHandling(data).request.status
            if(status === 200){                   
                setLoadingSearch(false)
                return data.data   
            }    
            setLoadingSearch(false)
        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setLoadingSearch(false)
        } 
    }

    const getFilterMachine = async(ids: string[], setLoadingSearch: Function): Promise< Machines[] | undefined>=>{
        try{
           const data  = await axiosMyJohnDeereInstance.post(
            API_END_POINT_MACHINE_GET_BY_IDS,
            {id_list: ids}) 
            if(data){
                const status = errorHandling(data).request.status
                if(status === 200){
                    setLoadingSearch(false)
                    return data.data  
                }
                setLoadingSearch(false) 
            }  
        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setLoadingSearch(false)
            console.log(e)
        } 
    }
    
   return{
    getMachine,
    getFilterMachine
    }
}