import React, {
    createContext,
    useState,
  } from 'react';
  import { useNavigate  } from "react-router-dom";
  import { useIntl } from 'react-intl';
  import { axiosMyJohnDeereInstance } from '../../Axios'; 
  import {
    AUTH_END_POINT_POST_LOGIN_CALLBACK,
  } from '../../Axios/johnDeereEndPoinds';
  import { useLocalStorageState } from '../../hooks/useLocalStorageState';

  class VoidContext implements UserContext {
    get changeAccessPageForm(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setChangeAccesPageForm(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get showAnimated(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowAnimated(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
     get loading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setLoading(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get showError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setShowError(): never {
      throw new Error('Cannot consume context outside of provider');
    }
    get statusRequest():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setStatusRequest(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get myJohnDeereToken():never {
      throw new Error('Cannot consume context outside of provider');
    }
    get setMyJohnDeereToken(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
    get tokenConfirmRequest(): Function {
      throw new Error('Cannot consume context outside of provider');
    }
  }

  interface UserContext {
    changeAccessPageForm: String,
    setChangeAccesPageForm: Function,
    showAnimated: boolean,
    setShowAnimated: Function,
    loading: boolean,
    setLoading: Function,
    showError: string,
    setShowError: Function,
    statusRequest: number,
    setStatusRequest: Function,
    myJohnDeereToken : string | undefined
    setMyJohnDeereToken: Function,
    tokenConfirmRequest: Function
  }

  export interface UserData {
    firstName: string,
    lastName: string,
    userId: number,
  }

  interface propsWithChildren {
    children: React.ReactNode
  }

  export interface MyJohnDeereToken{
    access: string,
    refresh:string
  }
  
  export const UserContext = createContext<UserContext>(new VoidContext());
  export const UserContextProvider= ({ children }: propsWithChildren): JSX.Element => {

    const intl = useIntl();
    const navigate = useNavigate();

    const [changeAccessPageForm, setChangeAccesPageForm]= useState<string>('login')
    const [showAnimated, setShowAnimated] = useState(false)
    const [loading, setLoading]= useState<boolean>(false)
    const [showError, setShowError]= useState<string>('')
    const [statusRequest, setStatusRequest] = useState<number>(0)
    const [myJohnDeereToken, setMyJohnDeereToken] = useLocalStorageState<string | undefined>(
      'MyJohnDeere-Token', undefined
   );  

    // const [refreshToken, setRefreshToken] = useLocalStorageState<string | undefined>(
    //   'MyJohnDeere-refreshToken', undefined
    // ); 
   
    const tokenConfirmRequest= async (token: string): Promise<any>=>{    
      try{
        const  data  = await axiosMyJohnDeereInstance.post(
          `${AUTH_END_POINT_POST_LOGIN_CALLBACK}/${token}`);
        if(data){
          setMyJohnDeereToken(data.data.access);
         // setRefreshToken(data.data.refresh)
        }
        return(data)
      }catch(error){
        return('error')
      }
    }


 
    return (
      <UserContext.Provider value={{
        changeAccessPageForm, 
        setChangeAccesPageForm,
        showAnimated,
        setShowAnimated,
        showError,
        setShowError,
        loading,
        setLoading,
        setStatusRequest,
        statusRequest,
        myJohnDeereToken, 
        setMyJohnDeereToken,
        tokenConfirmRequest

      }}>{children}</UserContext.Provider>
    );
  };



  