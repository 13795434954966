import { useEffect } from 'react';
import  { KeyedMutator } from "swr";
import { API_END_POINT_GET_USER} from '../Axios/johnDeereEndPoinds'
import { useAuthSWR } from './useAuthSWR';

export interface User{
  id: number,
  user: {
      username: string,
      email: string
  },
  firstname: string,
  lastname: string,
  is_active: true
}


export const useGetUser = () => {
  const {data, error, mutate}:{
    data: User, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_GET_USER])

    useEffect(() => {
      if (!data  && !error) {
        mutate();
      }
    }, [data, mutate]);

  return {
    data, 
    mutate
  };
};
