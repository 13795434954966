import React, { useContext } from 'react';
import {Tooltip} from  'antd';
import { AlertOutlined, HomeOutlined, SettingOutlined, BankOutlined, ToolOutlined, SoundOutlined, LogoutOutlined,TagOutlined } from '@ant-design/icons';
import './styles.scss'
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { UserContext } from '../../Context/UserContext/UsersContext';


export const VerticalNavbar: React.FC =()=>{

    const {setMyJohnDeereToken} = useContext(UserContext)
    const {layerBody, setLayerBody } = useContext(BodyContext)
    
    return(
        <div className="verticalNavbar_container">
            <div className="verticalNavbar_icons_container">  
                <div className={ layerBody === 'home' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div className={layerBody === 'home' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Inicio'
                        >
                            <HomeOutlined 
                                style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }} 
                                onClick={()=>{
                                    setLayerBody('home')
                                }}
                            />  
                        </Tooltip>  
                    </div>   
                </div>
                <div className='verticalNavbar_divide'></div>
                <div className={ layerBody === 'alert' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'} >
                    <div  className={layerBody === 'alert' ? 'verticalNavbar_selected ' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Alertas'
                        >
                            <AlertOutlined  style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('alert')
                                }}
                            />  
                        </Tooltip>  
                    </div>   
                </div>
                <div className={ layerBody === 'maintenanceAlert' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div  className={layerBody === 'maintenanceAlert' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Alertas de mantenimiento'
                        >
                              <SoundOutlined  style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('maintenanceAlert')
                                }}
                            />     
                        </Tooltip>  
                    </div>   
                </div>
                <div className={ layerBody === 'maintenance' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div  className={layerBody === 'maintenance' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Mantenimientos realizados'
                        >
                              <ToolOutlined  style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('maintenance')
                                }}
                            />     
                        </Tooltip>  
                    </div>   
                </div>
                <div className={ layerBody === 'maintenanceConfigurations' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div className={layerBody === 'maintenanceConfigurations' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Configuración de Mantenimientos'
                        >
                            <SettingOutlined 
                                style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('maintenanceConfigurations')
                                }} 
                            />   
                        </Tooltip> 
                    </div>   
                </div> 
                <div className={ layerBody === 'machinery' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div  className={layerBody === 'machinery' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Asignaciones'
                        >
                            <TagOutlined 
                                style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('machinery')
                                }}
                            />
                              {/* <span 
                                className="material-icons md-20 yellow margin cursor"
                                onClick={()=>{
                                    setLayerBody('machinery')
                                }} 
                                >agriculture</span>    */}
                        </Tooltip>  
                    </div>   
                </div>
                <div className={ layerBody === 'dealerships' ? 'verticalNavbar_icon backgroundOfSelected' : 'verticalNavbar_icon'}>
                    <div  className={layerBody === 'dealerships' ? 'verticalNavbar_selected' : 'verticalNavbar_not_selected'}></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Sucursales'
                        >
                            <BankOutlined  style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setLayerBody('dealerships')
                                }}
                            />    
                        </Tooltip>  
                    </div>   
                </div>       
                <div className='verticalNavbar_divide'></div>
                <div className='verticalNavbar_icon'>
                    <div  className= 'verticalNavbar_not_selected'></div>
                    <div className='verticalNavbar_tooltipAndIcon'>
                        <Tooltip
                            placement="right" 
                            title='Cerrar sesión'
                        >
                            <LogoutOutlined  style={{ fontSize: '16px', color: '#f6e001', margin: '15px 0px' }}
                                onClick={()=>{
                                    setMyJohnDeereToken(undefined)
                                }}
                            />    
                        </Tooltip>  
                    </div>   
                </div>                    
            </div>
        </div>
    )
}

export default VerticalNavbar;