import { axiosMyJohnDeereInstance } from '../Axios';
import {API_END_POINT_MACHINE_ALERT_GET_ALL} from '../Axios/johnDeereEndPoinds'
import { ResponseGetNotifications } from './useGetNotifications';

export interface FilterValues{
  value: string,
  id: string,
  key: number,
}
export const useLeakedNotifications = () => {
     

    const leakedNotifications = async (
      callback: Function,
      observations: boolean,
      note: boolean,
      colors?: string[] | undefined,
      startDate?: string  | undefined,
      endDate?:  string | undefined, 
      organizations?:  FilterValues[] | undefined,
      machines?:  FilterValues[]  | undefined,
      clave?: string | undefined,
      status?:  {
        value: string,
        label: string,
        key: string,
      }  | undefined,
      pageValue?: string | undefined,     
      
      
      ): Promise<ResponseGetNotifications> => {
        
        var params = new URLSearchParams();
        startDate && params.append("startDate", startDate)
        endDate && params.append("endDate", endDate)

        pageValue && params.append("page", pageValue)
        status && params.append("status", status.key)
        clave && params.append("alertCode", clave)
        observations && params.append("observations", observations.toString())
        note && params.append("notes", note.toString())
      
        colors?.map((color: string)=>{
          params.append("color", color);
        })
        organizations?.map((organization: FilterValues)=>{
          let organizationString = organization.key.toString()
          params.append("organization", organizationString);
        })
        machines?.map((machine: FilterValues)=>{
          let machineString = machine.key.toString()
          params.append("machine", machineString);
        })
       
        
      try {
        const {
          data,
        }: { data: any } = await axiosMyJohnDeereInstance.get(
          API_END_POINT_MACHINE_ALERT_GET_ALL,
          { 
            params
          }
        );
        callback()
        return data;
      } catch (newError) {
        console.log(newError);
        callback()
        throw newError;
      }
    };

  return {
    leakedNotifications
  };
};
