import {  useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_PONT_MACHINE_GET_SIMPLIFIED_LIST} from '../Axios/johnDeereEndPoinds'
import {  Machines } from '../Components/AlertsFilters/type';
import { useAuthSWR } from './useAuthSWR';


export interface ResponseGetMachines{
  count: number,
  next: string,
  previous: any
  results: Machines[]
}

export const useGetMachinesSimplifiedList = () => {

    const {data, error, mutate}:{
      data: Machines[],
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_PONT_MACHINE_GET_SIMPLIFIED_LIST])
    
 
     useEffect(() => {
      if (!data  && !error) {
        mutate();
      }
    }, [data, mutate]);

  return {
    data,
    mutate,
  };
};
