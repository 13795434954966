import { Routes , Route, Navigate} from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext/UsersContext';
import { PublicRoutes } from '../PublicRoutes';
import { PrivateRoutes } from '../PrivateRoutes';


const MainRutes = () => {
 const {myJohnDeereToken } = useContext(UserContext);


  return (
    <Routes>
        {
            myJohnDeereToken && myJohnDeereToken !== 'undefined'
                ? <Route path="/*" element={<PrivateRoutes />} />
                : <Route path="/*" element={<PublicRoutes />} />
        }
        <Route path='*' element={<Navigate to='/login' replace />} />       
    </Routes> 
  );
}

export default MainRutes;