import React from 'react';
import  Logo  from '../../assets/images/LogoHorizontalConTransparenciaAmarillo.png'
import Row from  'antd';
import './styles.scss'
import { useGetUser } from '../../Services/useGetUser';

export const Navbar: React.FC =()=>{

    const {data: dataUser}=useGetUser()
    return(
        <div className="navbar_container">
            <div className="navbar_row">
                <img src={Logo} alt="Logo johon Deere" />
                <div className='navbar_divide'></div>
                <h1>{dataUser?.firstname} {dataUser?.lastname}</h1>
                {/* <p>{dataUser?.user.username}</p> */}
            </div>
        </div>
    )
}

export default Navbar;