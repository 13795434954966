import React, { useContext, useEffect, useState} from 'react';
import { AlertOutlined } from '@ant-design/icons';
import { Notifications, ResponseGetNotifications, useGetNotifications } from '../../Services/useGetNotifications'; 
import { Button, Form, Modal, Popover, Timeline } from 'antd';
import './styles.scss'
import AlertsFilters, { NotificationFilters } from '../AlertsFilters/AlertsFilters';
import { SpinerHorizontal } from '../SpinerHorizontal/SpinerHorizontal';
import { useLeakedNotifications } from '../../Services/useGetLeakedNotifications';
import moment from '../../../node_modules/moment/moment';
import { BodyContext } from '../../Context/BodyContext.tsx/BodyContext';
import { WarningOutlined, FireOutlined, CheckOutlined, FileTextOutlined, VerticalAlignTopOutlined, ToolOutlined } from '@ant-design/icons';
import Table, { ColumnsType } from 'antd/es/table';
import type {  TableProps } from 'antd/es/table';
import { AlertsProps } from './types';
import TextArea from 'antd/es/input/TextArea';
import AlertsModal from '../AlertsModal';
import EditNotesModal from '../EditNotesModal';

export interface DataType {
    index: number;
    id: number;
    organization_name: string;
    organization_id: number 
    machine_id: number;
    machine_name: string;
    code: string;
    description: string;    
    dealership_name: string;
    color: string;
    equipment_type_name: string;
    note: string | undefined;
    observation: string | undefined;
    status: string,
    status_description: string    
}

export const Alerts: React.FC<AlertsProps> =({

})=>{

    const {data: dataAlerts, mutate} = useGetNotifications() 
    const { leakedNotifications } = useLeakedNotifications() 
    const {
        setSelectedAlertNotification,
        openAlertModal,
        setOpenAlertModal,
        fullScreen,
        setFullScreen,
        setContextDataAlert,
    } = useContext(BodyContext)
    const [form] = Form.useForm();
    const [form_edit_note] = Form.useForm();

    const [dataTimeline, setDataTimeline] = useState<ResponseGetNotifications>()
    const [dataTable, setDataTable] = useState<DataType[]>([])
    const [loadingTableData, setLoadingTableData] = useState<boolean>(false)
    const [loadingFilters, setLoadingFilters] = useState(false)
    const [addNote, setAddNote] = useState<{
        show: boolean,
        note: string | undefined 
        alertId: number | undefined
    }>({
        show: false,
        note: undefined,
        alertId: undefined
    })
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 50, // Número de elementos por página
        total: 0, // Número total de elementos
      });

    useEffect(() => {
        let timeFullScreen = fullScreen ? 5 * 1000  : 11 * 60 * 1000
        if(fullScreen){
            const intervalId = setInterval(() => {
                mutate()
            }, timeFullScreen ); 
            return () => clearInterval(intervalId);
        }
    }, [fullScreen]); 
    
    useEffect(()=>{
        setLoadingTableData(true)
        if(dataAlerts ){
            rowData() 
            setLoadingTableData(false)
        }
    },[dataAlerts])

    let code = (data: Notifications) =>{
        let letters = data.alert_definition.three_letter_acronym || undefined;
        let codeNumber = data.alert_definition.suspect_parameter_name || undefined
        let failure_mode_indicator =  data.alert_definition.failure_mode_indicator || undefined
        let code: string = ''

        if(!failure_mode_indicator && failure_mode_indicator < 10){
            failure_mode_indicator = '0'+ failure_mode_indicator.toString()
        }
        if(!letters && !codeNumber && !failure_mode_indicator ){

            var regex = /(ROJO|RED)\s*([A-Z0-9]+\s\d+\.\d+)/i;
            var resultado = data.alert_definition.description.match(regex);
            code = resultado ? resultado[2].toString() : ''
        }else{
            if(letters ){
                code = letters
            }if(codeNumber){
                var addZero = String(codeNumber).padStart(6, '0');
                if(code !==''){
                    code = code +' ' + addZero
                }else{
                    code = addZero
                }
               
            }if(failure_mode_indicator){
                var addZero = String(failure_mode_indicator).padStart(2, '0');
                code = code + '.' + addZero
            }
        }
        return code
    }

    const rowData = (data? : ResponseGetNotifications) =>{
        let filterData: DataType[]= []
        let list: ResponseGetNotifications = data ? data : dataAlerts
        let values: NotificationFilters = form.getFieldsValue() 
        let day: any =   values.datePicker   
        const startDate = values.datePicker && moment(day[0].$d).format('YYYY-MM-DD')
        const endDate = values.datePicker && moment(day[1].$d).format('YYYY-MM-DD')
       
        if(list && list.alerts){
          list.alerts.map((data, index)=>{
            let dealershipName = data.machine.dealership && data.machine.dealership.name ?  data.machine.dealership.name : ''    
            let errorCode = code(data)
            filterData.push({
                index: index,
                id: data.id,
                organization_name: data.machine.organization.name,
                organization_id: data.machine.organization.id,
                machine_id: data.machine.id,
                machine_name: data.machine.name,
                code: errorCode || '' ,
                description: data.alert_definition.description,
                dealership_name:  dealershipName,    
                color: data.color ,
                equipment_type_name: data.machine.equipment_type.name,
                note: data.note || undefined,
                observation: data.observations || undefined,
                status: data.status.toString(),
                status_description : data.status  == 1 ? 'Atendido' : data.status == 2 ? 'Desatender' : 'Sin atender'                        
            })
          })        
          setPagination({
            ...pagination,
            page: list.current_page,
            pageSize: 50,
            total: list.count,
          });
          //Cargo los filtos en una variable para que el get de alertas traiga las alertas con los filtros si es que tiene //pero si full screen es true, no cambio 
          //el valor de contexDAtaAlert porque form.getFields viene vacío porque el menú filtro está oculto         
          if(!fullScreen){
            setContextDataAlert({
                page: list.current_page,
                colors: values.colors,
                datePicker: values.datePicker,
                machines: values.machines,
                organizations: values.organizations,
                clave: values.clave,
                observations: values.observations,
                status: {
                  value: values.status?.value,
                  label: values.status?.label,
                  key: values.status?.key,
                },
                note: values.note
            })
          }        
        }       
        setDataTable(filterData)
      }

      const handleEscapeKey = (event: any) => {
        if (event.key === 'Escape') {
          setFullScreen(false)
        }
      };

      useEffect(() => {           
        document.addEventListener("keydown", handleEscapeKey, false);    
        return () => {
          document.removeEventListener("keydown", handleEscapeKey, false);
        };
      }, []); 
    
    

    const callback = () =>{
        setLoadingFilters(false)
        setLoadingTableData(false)
    }

    
    const handleSeeAll =()=>{
        form.resetFields() 
        setContextDataAlert()
        handleGetPage()        
    }
   

    const renderAlertIcons = (text: string, data: DataType) => {
        let icon
        switch(text){
            case 'RED':
                icon = <div style={{backgroundColor: 'red', width: '8px', height: '8px', borderRadius:'50%'}}></div>
                break;
            case 'YELLOW':
                icon = <div style={{backgroundColor: 'yellow', width: '8px', height: '8px', borderRadius:'50%'}}></div>
                break;
            case 'BLUE':
                icon = <div style={{backgroundColor: 'blue', width: '8px', height: '8px', borderRadius:'50%'}}></div>
                break;
            case 'GRAY':
                icon = <div style={{backgroundColor: 'gray', width: '8px', height: '8px', borderRadius:'50%'}}></div>
                break;
            default:
                icon = <div style={{backgroundColor: '#545e6e77', width: '8px', height: '8px', borderRadius:'50%'}}></div> 
        }
        return <div style={{display: 'flex', alignItems: 'center'}}>
            {icon}{data.observation && <ToolOutlined style={{marginLeft: '15px'}} />}
        </div> 
    }

    const handlePageChange: TableProps<DataType>['onChange'] = (
        pagination,
        filters,
        sorter,
        extra
      ) => {
        // Actualizar la página actual y la cantidad de elementos por página
        let page = pagination.current;
        let pageSize = pagination.pageSize;
        if (page && pageSize) {
          setPagination({
            ...pagination,
            page: page,
            pageSize: pageSize,
            total: 0,
          });
        }
      };

      
    const handleGetPage = async (page?: number, loadingTable?: boolean) => {
        let values = form.getFieldsValue() 
        let filters: NotificationFilters = values ? values : undefined 
        let pageNumber: number = page ? page : 1  
        loadingTable && setLoadingTableData(true)
        let day: any =   filters.datePicker
        const startDate = filters.datePicker && moment(day[0].$d).format('YYYY-MM-DD')
        const endDate = filters.datePicker && moment(day[1].$d).format('YYYY-MM-DD')
        const data = await leakedNotifications( 
            callback,             
            filters.observations,
            filters.note, 
            filters.colors, 
            startDate, endDate, 
            filters.organizations, 
            filters.machines,
            filters.clave,
            filters.status,
            pageNumber.toString())
        
        if (data) {
            rowData(data);
            // setPagination({
            // ...pagination,
            // page: data.current_page,
            // pageSize: 50,
            // total: data.count,
            // });          
        }
    };

    const handelRender = (text: string, data: DataType) => {
        return  data.status == 'Desatender'
            ? <a style={{color: '#8080805e'}}>{text}</a>
            :
            <a >{text}</a> 
    }
    const handelRenderStatus = (text: string, data: any) => {
        let color;
        switch(text){
            case 'Atendido':
                color = '#f6e201'//'#f6e201c5'//
                break;
            case 'Sin atender':
                color = 'red'
                break;
            case 'Desatender':
                color = '#8080805e'
                break;
            default:
                color = 'gray'
        }
        return <a     
            onClick={()=>{                                
            }}
            style={{color: color}}
        >{text}</a> 
    }

    const handleCancel =()=>{
        setAddNote({
            show:false,
            note: undefined,
            alertId: undefined
        })
        form_edit_note.resetFields()
    }

 
    
    
    const columns: ColumnsType<DataType> = [
        {
            title: '',
            dataIndex: 'color',
            width: 70,
            key: 'color',
            render: renderAlertIcons,  
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            width: 140,
            render: handelRender,
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
            title: 'Organización',
            dataIndex: 'organization_name',
            key: 'organization_id',
            width: 200,
            render: handelRender,
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
          title: 'Maquinaria',
          dataIndex: 'machine_name',
          key: 'machine_id',
          width: 200,
          render: handelRender,
          ellipsis: true,
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            };
          }
        },
        {
          title: 'Sucursal asignada',
          dataIndex: 'dealership_name',
          key: 'dealership_name',
          width: 130,
          render: (text: string, data: DataType)=>data.status === 'Desatender' 
            ? <a style={{color: '#8080805e' }}>{text ? text : 'Sin asignación'}</a>
            : <a>{text ? text : 'Sin asignación'}</a>,
          ellipsis: true,
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            };
          }
        },
        {
            title: 'Tipo de maquinaria',
            dataIndex: 'equipment_type_name',
            width: 130,
            key: 'equipment_type_name',
            render: handelRender,
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }               
        },
        
        {
          title: 'Descripción',
          dataIndex: 'description',
          key: 'description',
          render: handelRender,
          ellipsis: true,
          onCell: (record, rowIndex) => {
            return {
              onClick: () => {
                onClickRow(record)
              }
            };
          }
        },
        {
            title: 'Estado',
            dataIndex: 'status_description',
            key: 'status',
            width: 110,
            render: handelRenderStatus,
            ellipsis: true,
            onCell: (record, rowIndex) => {
                return {
                  onClick: () => {
                    onClickRow(record)
                  }
                };
              }
        },
        {
          title: 'Notas',
          dataIndex: 'note',
          key: 'index',
          width: 80,
          ellipsis: true,
          render: (text: string, data: DataType)=> <a>
            {
                text === undefined
                ? <VerticalAlignTopOutlined 
                    onClick={()=>setAddNote({
                        show: true,
                        note: text,
                        alertId: data.id
                    })}
                /> 
                : <Popover placement="topRight" content={text}>
                    <FileTextOutlined  
                        style={{ fontSize:'16px', color: data.status === 'Desatender' ? '#8080805e' : 'gray'}}
                        onClick={()=>setAddNote({
                            show: true,
                            note: text,
                            alertId: data.id
                        })}
                    /> 
                </Popover>
            }</a>,          
        },
    ];
    
    const onClickRow = (record: DataType) =>{         
        setSelectedAlertNotification(record)
        setOpenAlertModal(true) 
    }  


 

    return(
        <>
            { addNote.show && 
                <EditNotesModal 
                    addNote={addNote}   
                    handleCancel={handleCancel}
                    form_edit_note={form_edit_note}
                    handleGetPage={handleGetPage}
                />}
            {
                fullScreen ?
                <div className='full_screen'>
                    <div className='full_screen_body'>
                        {
                            loadingTableData
                            ? <SpinerHorizontal/> 
                            : <Table 
                                columns={columns} 
                                dataSource={dataTable}  
                                pagination={{
                                    current: pagination.page,
                                    pageSize: 50,
                                    total: pagination.total,
                                    onChange: (e)=>{handleGetPage(e, true)},
                                    showSizeChanger: false,
                                }}
                                onChange={handlePageChange}
                                scroll={{ y: 700 }}
                                
                            />    
                        }  
                    </div>
                </div>
                :
                <div className="alerts_container">
                    <div className='alerts_title_container'>
                        <div className='alerts_iconsAndTitle'>
                            <AlertOutlined style={{ fontSize: '18px', color: 'white', marginLeft: '15px' }} />    
                            <p>ALERTAS</p>
                        </div>                        
                        <Button
                            className='alerts_btn_fullScreen'
                            onClick={()=>{setFullScreen(true)}}                
                            disabled={loadingTableData}
                        >
                            Pantalla completa
                        </Button>
                    </div>            
                    <div className='alerts_body_container'>
                        <div className='alerts_filters'>
                            <AlertsFilters 
                                form={form}
                                loadingTableData={loadingTableData}   
                                handleGetPage={handleGetPage}
                                handleSeeAll={handleSeeAll}
                            />
                        </div>
                        <div className='alerts_timeline'>                                                                                        
                            {
                            loadingTableData
                            ? <SpinerHorizontal/> 
                            : <Table 
                                    columns={columns} 
                                    dataSource={dataTable}  
                                    pagination={{
                                        current: pagination.page,
                                        pageSize: 50,
                                        total: pagination.total,
                                        onChange: (e)=>{handleGetPage(e, true)},
                                        showSizeChanger: false,
                                    }}
                                    onChange={handlePageChange}
                                    scroll={{ y: 550 }}
                                />    
                            }                                                                     
                        </div>                
                    </div>                      
                </div>        
            }     
            {openAlertModal && <AlertsModal handleGetPage={handleGetPage}/>}  
        </>
    )
}

export default Alerts;


