import { useContext, useEffect } from 'react';
import useSWR, { KeyedMutator } from "swr";
import { axiosMyJohnDeereInstance } from '../Axios';
import {API_END_POINT_DEALERSHIP_GET_ALL} from '../Axios/johnDeereEndPoinds'
import {  Dealership, Machines } from '../Components/AlertsFilters/type';
import { UserContext } from '../Context/UserContext/UsersContext';
import { useAuthSWR } from './useAuthSWR';


export interface ResponseGetDealership{
  count: number,
  next: string,
  previous: any
  results: Dealership[]
}

export const useGetAllDealershep = () => {
    
    const {data, error, mutate }:{
      data: ResponseGetDealership, 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_POINT_DEALERSHIP_GET_ALL])

    
    useEffect(() => {
      if (!data && !error) {
        mutate();
      }
    }, [data, mutate]);
  
    return {
      data,
      mutate,
    };
};
