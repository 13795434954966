import React, { useContext, useEffect, useState } from 'react';
import {  TagOutlined } from '@ant-design/icons';
import { Form, Button,  Select, Tooltip, Table, Switch  } from 'antd';
import { useGetAllDealershep } from '../../Services/useGetAllDealershep';
import { MachineryContext } from '../../Context/MachineryContext.tsx/MachineryContext';
import { useAssociateDealerships } from '../../Services/useAssociateDealerships';
import { Machines } from '../AlertsFilters/type';
import { EditMachinery } from './type';
import { MachineryModa } from '../MachineryModal/MachineryModal';
import './styles.scss'
import { useGetMachinesSimplifiedList } from '../../Services/useGetMachinesSimplifiedList';
import { ColumnsType } from 'antd/es/table';
import { useMachine } from '../../Services/useMachine';

export type ListMachines={
    name: string,
    idMachine:number,
    checked: boolean,
    idDealership: number | undefined
}

interface DataType {
    machine_name: string,
    machine_id: number,    
    assignedDealerShips_name: string,
    assignedDealerShip_id: number,
    key: number
}

export const MachineryTable: React.FC =()=>{

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    

    const {createAssociation} = useAssociateDealerships()
    const {data: machines}= useGetMachinesSimplifiedList()
    const { Option } = Select;
    const {data: dealerships} = useGetAllDealershep()
    const { getFilterMachine } = useMachine()

    const [form] = Form.useForm();
    const [formSelect] = Form.useForm();
    const [formmSearch] = Form.useForm();
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false)   
    const [listMachine, setListMachine] = useState<DataType[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);
    const [enablecheck, setEnableChek] = useState<boolean>(false)
    const [selectedMachineFiltred, setSelectedMachineFiltred]= useState<string[] | undefined>()
    const [selectedMachine, setSelectedMachine] = useState<EditMachinery>({
        machineryName: "",
        machinery_id: 0,
        dealerShip_id: 0,
        assignedDealerShips: "",
    })
        
    const {
        setSelectedDealerShips, 
        selectedDealerShips,
        isModalOpen,
        setIsModalOpen    
    } = useContext(MachineryContext) 

    const filtersByDealership = ()=>{
        let filter: {text: string, value: string}[] = []
        if(dealerships){
            dealerships.results.map((item)=>{
                filter.push({
                    text: item.name,
                    value: item.name,
                })
            })
        }
        filter.push({
            text: 'Sin organizacion asignada',
            value: 'Sin organizacion asignada',
        })
        return filter
    }
    
    const columns: ColumnsType<DataType> = [
        {
            title: 'Maquinaria',
            dataIndex: 'machine_name',
            key: 'key',  
            ellipsis: true
        },
        {
            title: 'Sucursal asignada',
            dataIndex: 'assignedDealerShips_name',
            key: 'key',
            ellipsis: true,
            render: (text)=><a>{text}</a>,
            filters: filtersByDealership(),
            onFilter: (value, record) => typeof value === 'string' && record.assignedDealerShips_name.includes(value),
            filterSearch: true,
        },
    ];
    
    useEffect(()=>{
        uploadListMachine()
    },[machines]) 

    

    const uploadListMachine = (newData?: Machines[]) =>{
        let dataToLoadIntoTable: Machines[] = newData ? newData : machines
        let newlistMachine: DataType[]= []
        dataToLoadIntoTable.map((machine, index)=>{           
        newlistMachine.push({
            machine_name: machine.name,
            machine_id: machine.id,    
            assignedDealerShips_name: machine.dealership && machine.dealership.name ? machine.dealership.name : 'Sin organizacion asignada',
            assignedDealerShip_id: machine.dealership && machine.dealership.id ? machine.dealership.id : 0,
            key: machine.id
        })
        })            
        setListMachine(newlistMachine)       
    }
   
    const handleSeeAll = () =>{
        uploadListMachine(machines)
        setSelectedMachineFiltred(undefined)
        formmSearch.resetFields()        
    } 

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
      };

    const hasSelected = selectedRowKeys.length > 0;
    
    const onClickRow = (record: DataType) =>{
        setIsModalOpen(true)
        setSelectedMachine({
            machineryName: record.machine_name,
            machinery_id: record.machine_id,
            dealerShip_id: record.assignedDealerShip_id ? record.assignedDealerShip_id : 0,
            assignedDealerShips: record.assignedDealerShips_name,
        })
    }

    const handleSaveChekes = async()=>{       
        setLoading(true);               
        if(selectedDealerShips && selectedRowKeys.length > 0){ 
            let assignments = new Map(); 
            const machines_id = Object.values(selectedRowKeys);
            machines_id.map((machine)=>{
                assignments.set(`${machine}`, selectedDealerShips);
            })
            const obj = Object.fromEntries(assignments)

            const data = await createAssociation(obj, !!selectedMachineFiltred)
            if(data == 'ok'){
                if(selectedMachineFiltred && selectedMachineFiltred.length > 0){
                   searchMachinery() 
                } 
                setSelectedRowKeys([])
                setLoading(false);
                form.resetFields();
                formSelect.resetFields();
                setSelectedDealerShips(undefined)                
            }
        }else{
            let mjs: string = selectedDealerShips ? 'Debe seleccionar una o más maquinarias' :'Debe seleccionar una Organización'
            setLoading(false)
        }       
    }

    const handleRowSelectionChange = (enable: boolean) => {
        setEnableChek(enable);
        if(!enable){
            setSelectedDealerShips(undefined)
            formSelect.resetFields()
            setSelectedRowKeys([])
        }
      };

    const handleFinish =(
        event: {
            selectedMachinery: {key: string}[]
        }
    )=>{
        let ids: string[] = []
        event.selectedMachinery.map((id)=>{
            ids.push(id.key)
        })
        setLoadingSearch(true)   
        setSelectedMachineFiltred(ids)  
        searchMachinery(ids)        
    }

    const searchMachinery = async(ids?: string[])=>{
        // si la función viene de handlefinish, como se cambia el estado de selectedMachineFiltred en esa función, no llega a actualizarce el estado, por ese motivo decido pasarlo por parámetro
        let listIds: string[] | undefined = ids ? ids : selectedMachineFiltred ? selectedMachineFiltred : undefined 
        if(listIds){
            const data = await getFilterMachine(listIds, setLoadingSearch)
            if(data){
                uploadListMachine(data)
            } 
        }       
    }

    return(
        <div className="machinery-table_container">
            <div className='machinery-table_title_container'>
                <TagOutlined style={{ fontSize: '18px', color: 'white', marginLeft:'10px'}}/>
                {/* <span  className="material-icons md-20 white machinery_margin-icon">agriculture</span>    */}
                <p>ASIGNACIONES</p>
            </div>
            <div className='machinery-table_body_container'>
                <div className='machinery-table_submenu'>
                    <div className='machinery-table_subtitle'>
                        <p><div className='machinery-table_point'> </div> MENU</p>
                    </div>
                    <div  className="machinery_seeker">
                        <Form
                          form={formmSearch}
                          id='form-Search'
                          onFinish={handleFinish}>
                            <Form.Item
                                name='selectedMachinery' 
                                style={{margin:'0px', padding:'0px'}}
                            >
                                <Select
                                    mode="multiple" 
                                    style={{ width: '100%',  marginBottom: '24px' }}
                                    labelInValue
                                    tokenSeparators={[',']}
                                    placeholder="Buscar maquinaria"                                
                                >
                                    {machines && machines &&
                                    machines.map((machine) => (
                                    <Option value={machine.name} key={machine.id}>
                                        {machine.name}
                                    </Option>
                                    ))}
                                
                                </Select>                                 
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    className="global_btn-ghost"
                                    loading={loadingSearch}
                                    htmlType="submit"
                                >
                                    Buscar
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Button
                        className="global_btn-ghost"
                        onClick={handleSeeAll}
                        //loading={loadingBtnSave}
                    >
                        Ver todos
                    </Button>
                </div>
                {isModalOpen && 
                    <MachineryModa 
                        selectedMachine={selectedMachine} 
                        setSelectedMachine={setSelectedMachine}
                        searchMachinery={searchMachinery}
                        selectedMachineFiltred={selectedMachineFiltred}
                        
                    />
                }
                <div className='machinery-table_timeline'> 
                    <div className='machinery-table_menu'>
                        <Form.Item label="Habilitar asignación masiva" style={{color: 'white'}}>
                            <Switch 
                                checked={enablecheck} 
                                onChange={handleRowSelectionChange}
                            />
                        </Form.Item>
                        <div className={`machinery-table_container-select ${!enablecheck ? 'enable-animation' : ''}`}>
                            <Form
                                form={formSelect}
                                id='form-select'
                            >
                                <Form.Item
                                    name='form-select'
                                    className='machinery-table_formItem'
                                >                            
                                    <Select
                                        placeholder= "Elegir organización"
                                        style={{minWidth:' 200px'}}
                                        labelInValue
                                        tokenSeparators={[',']}
                                        onChange={(e)=>{
                                            setSelectedDealerShips(e.value)}}
                                    > 
                                        {dealerships &&
                                            dealerships.results.map((dealership) => (
                                            <Option value={dealership.id} key={dealership.id}>
                                                {dealership.name}
                                            </Option>
                                        ))}
                                    </Select>                                 
                                </Form.Item>
                            </Form>
                            <Tooltip
                                placement="top" 
                                title='Elije una organización y haz check en las maquinarias que quieres asignarle'
                            >
                                <Button 
                                    className={!hasSelected || !selectedDealerShips ? "":"global_btn-ghost"}
                                    style={{width:'100px', marginLeft:'10px'}}
                                    //type="primary" 
                                    onClick={handleSaveChekes}
                                    disabled={!hasSelected || !selectedDealerShips} 
                                    loading={loading}>
                                    Guardar
                                </Button>
                            </Tooltip>                      
                        </div>
                    </div>              
                    <Table 
                        columns={columns} 
                        dataSource={listMachine} 
                        rowSelection={enablecheck ? rowSelection : undefined}  
                        pagination={{ pageSize: 50, showSizeChanger: false, }} 
                        onRow={ !enablecheck ?(record, index) => {
                            return {
                                onClick: (event) => {
                                onClickRow(record)
                                }
                            };
                        } : undefined}
                        style={{marginBottom: '20px'}} 
                        scroll={{y:600}}
                    />
                </div>  
            </div>
        </div>
    )
}

export default MachineryTable;

