import React, { useContext,  useState } from 'react';
import { Select, Modal, Button, Form } from 'antd';
import { MachineryContext } from '../../Context/MachineryContext.tsx/MachineryContext';
import "./style.scss"
import { useGetAllDealershep } from '../../Services/useGetAllDealershep';
import { MachineryModalProps } from './type';
import { useAssociateDealerships } from '../../Services/useAssociateDealerships';


export const MachineryModa: React.FC<MachineryModalProps> = ({
  selectedMachine,
  setSelectedMachine,
  searchMachinery,
  selectedMachineFiltred,
})=>{

  const {createAssociation} = useAssociateDealerships()
  const {data: dealerships} = useGetAllDealershep()
  const { Option } = Select;
  const [form_assignment] = Form.useForm();
  

  const [editData, setEditData] = useState<boolean>(false)

  const { 
    isModalOpen,
    setIsModalOpen,
    setSelectedDealerShips,
    selectedDealerShips
  } = useContext(MachineryContext)  

  const [spanError, setSpanError] = useState<string>('')
  const [loadingSave, settLoadingSave] = useState<boolean>(false)

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedMachine({
      machineryName: "",
      machinery_id: 0,
      assignedDealerShips: "",
    })    
  };

  const handleSaveChekes = async()=>{   
      settLoadingSave(true)          
      let assignments = new Map(); 
      assignments.set(`${selectedMachine.machinery_id}`, selectedDealerShips);
      const obj = Object.fromEntries(assignments)
      const data = await createAssociation(obj, !!selectedMachineFiltred)
      if(data == 'ok'){
          setSelectedDealerShips()
          if(selectedMachineFiltred){
            searchMachinery(selectedMachineFiltred) 
          }
          
      }  
      settLoadingSave(false)    
      handleCancel()       
}
  return(
      <>
      <Modal 
        title={selectedMachine.machineryName + ' / Sucursal asignada: '} 
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button           
            className='global_btn-modal-ghost'
            key="edit" 
            onClick={()=>{
              setEditData(!editData)
            }}
          >
            {editData ? 'Cancelar edición' : 'Editar' }
          </Button>,
          <Button 
            className='global_btn-modal-ghost'
            key="cancel" 
            //loading={loading}
            onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            className='global_btn-modal-primary'
            key="save"
            type="primary"
            loading={loadingSave}
            onClick={handleSaveChekes}
          >
            Guardar
          </Button>,
        ]}>        
        {
          editData ?
            <>
              <div className='machineryModal_select modal'>
                <Form
                    form={form_assignment}
                    onFinish={handleSaveChekes}  
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Campo obligatorio',
                      },
                    ]}
                  >
                    <Select
                        placeholder= "Elegir organización"
                        labelInValue
                        style={{ width: '100%'}}
                        tokenSeparators={[',']}
                        defaultValue={selectedMachine.assignedDealerShips && selectedMachine.dealerShip_id ? 
                          { 
                            key: selectedMachine.dealerShip_id.toString(),
                            label: selectedMachine.assignedDealerShips, // Puedes asignar cualquier string como label
                            title: '', // Puedes asignar cualquier string como title
                            value: selectedMachine.dealerShip_id || 0
                          } 
                          : undefined}
                        onChange={(e:{ 
                          key: string,
                          label: string,
                          title:string
                          value: number,
                        })=>{
                            setSelectedDealerShips(e.value)
                          }}
                    > 
                        {dealerships &&
                            dealerships.results.map((dealership) => (
                            <Option value={dealership.id} key={dealership.id}>
                                {dealership.name}
                            </Option>
                        ))}
                    </Select>
                  </Form.Item>              
                </Form>
               
                {/* <span>{spanError}</span> */}
              </div>  
            </> 
            : <h4 className='machineryModal_h4'>{selectedMachine.assignedDealerShips ? selectedMachine.assignedDealerShips : 'Sin organización asignada' }</h4> 
        }      
      </Modal>
    </>)
}