import { useContext, useEffect } from 'react';
import { KeyedMutator } from "swr";
import {API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING} from '../Axios/johnDeereEndPoinds';
import { MaintenanceAlert } from '../Context/ManitenanceContext/types';
import { useAuthSWR } from './useAuthSWR';
import { MachineryContext } from '../Context/MachineryContext.tsx/MachineryContext';
import { FilterValues } from './useGetLeakedNotifications';


export interface ResponseGetMaintenanceAlert{
  alerts: MaintenanceAlert[],
  count: number,
  current_page: number,
  next_page: number,
}

export const useGetAllMantenanceAlert = () => {

  const {dataMaintenanceAlert}= useContext(MachineryContext)
  var params = new URLSearchParams();
     
  if(dataMaintenanceAlert){
    if(dataMaintenanceAlert?.machines){
      dataMaintenanceAlert.machines.map((item: FilterValues)=>{
            params.append("machine", item.key.toString())
        })           
    }
    dataMaintenanceAlert?.organizations && params.append("organization", dataMaintenanceAlert.organizations.key.toString())
    dataMaintenanceAlert?.dealership && params.append("dealership", dataMaintenanceAlert.dealership.key.toString())
    dataMaintenanceAlert?.page && params.append("page", dataMaintenanceAlert.page.toString())
  } 

  const {data, error, mutate}:{
    data: ResponseGetMaintenanceAlert, 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_MAINTENANCE_GET_ALL_UPCOMING], params)
    
  useEffect(() => {
    if (!data && !error) {
      mutate();
    }
  }, [data, mutate]);

  return {
    data,
    mutate,
  };
};
