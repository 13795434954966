import React, {  } from 'react';
import { Form, Button, Select,  } from 'antd';
import './styles.scss'

import { MaintenanceConfigurationsFiltersProps } from './type';
import { useGetEquipamentsTypes } from '../../Services/useGetEquipamentsTypes';

export interface OrganizationsForSelector{
  value: string
  label: string
}

export const MaintenanceConfigurationsFilters: React.FC <MaintenanceConfigurationsFiltersProps>=({
  form_maintenanceConfigurationsFilters,
  handleGetPage,
  loadingTable,
  handleSeeAll
})=>{

  const { Option } = Select;
 
  const {data: equipmentType} = useGetEquipamentsTypes()


  const handleApplyFiltros = () =>{
    handleGetPage(undefined, true)
  }

  return(
      <div className="maintenanceConfigurationsFilters_container">
          <p><div className='alertsFilters_point'></div> FILTROS</p>
          <Form
            form={form_maintenanceConfigurationsFilters}
            onFinish={handleApplyFiltros}  
          >      
        
            <Form.Item 
              name='equipment_type'
              className="maintenanceConfigurationsFilters_selectOrganizations">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                labelInValue
                tokenSeparators={[',']}
                placeholder="Tipo de maquinaria"
              >
                 {equipmentType &&
                equipmentType.results.map((item) => (
                  <Option value={item.name} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              
              </Select>  
            </Form.Item>          
           
            <Form.Item>
              <Button
                className="maintenanceConfigurationsFilters_filtersBtn"
                htmlType="submit"
                disabled={loadingTable}
              >
                Aplicar
              </Button>
            </Form.Item>
          </Form>
          <Button
                className="maintenanceConfigurationsFilters_filtersBtn"
                onClick={handleSeeAll}
                disabled={loadingTable}
              >
                Ver todos
              </Button>
      </div>
    )
}

export default MaintenanceConfigurationsFilters;

