import {useContext} from 'react';
import justLogo from '../../assets/images/justLogo.png'
import johnDeere from '../../assets/images/johnDeere.png'
import StartupBackground from '../../Components/StartupBackground/StartupBackground';
import Login from '../../Components/Login/Login';
import { UserContext } from '../../Context/UserContext/UsersContext';
import AnimationLoading from '../../Components/Spiner/AnimationLoading';
import './accessPage.scss';

export const AccessPage = ()=> {

    const { changeAccessPageForm, loading} = useContext(UserContext)    
    
  return (
      <div className='accessPages-container'> 
        {loading && <AnimationLoading/>}
       <StartupBackground/>
        <div className = 'accessPages-container-left'>
            <div className='accessPages-loginIcon accessPages-loginIcon-johnDeere'>
                <img src={justLogo} alt="ícono logo" className=' animate__animated animate__pulse'/>
            </div>       
            <div className='accessPages-loginIcon accessPages-loginIcon-justLogo'>
                <img src={johnDeere} alt="ícono logo" />
            </div>                  
        </div>
        <div className='accessPages-container-right ' >
         {changeAccessPageForm === 'login' && <Login/> }
        </div>  
      </div>
    
  );
}

export default AccessPage;