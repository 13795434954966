import React, {useEffect, useState, useContext} from "react";
import { UserContext } from "../../Context/UserContext/UsersContext";
import { useNavigate  } from "react-router-dom";
import AnimationLoading from "../Spiner/AnimationLoading";
import { messagesGeneric } from '../../Messages/messagesGeneric';
import { useIntl } from 'react-intl';
import './processingAccess.scss';

export const ProcessingAccess = ()=>{

    const {tokenConfirmRequest} = useContext(UserContext)
    const navigate = useNavigate();
    const intl = useIntl();

    let search = window.document.location.search
    const[showError, setShowError] = useState<string>('');    

    useEffect(()=>{
        if(search){
            let indice = search.indexOf("&");
            let tokenConfirm = search.substring(0, indice);
        handleChange(tokenConfirm)}
    },[])

    
    //Login
    const handleChange = async (tokenConfirm: string)=>{
        await tokenConfirmRequest(tokenConfirm)
        .then(
            (data: any) => {
                if(data.status === 200){
                    navigate('/')
                    setShowError('')                  
                }else{
                    setShowError(intl.formatMessage(messagesGeneric.contactAdministrator))
                }
            },
            (error: any) => {
                setShowError(intl.formatMessage(messagesGeneric.contactAdministrator))
                console.log(error)
            } 
        )
    };
    return(
        <div className='processingAccess'>
            <div className='processingAccess_container_p'>
                <p>AGUARDE UN MOMENTO, ESTAMOS PROCESANDO SU ACCESO </p>
                <p className='processingAccess_container_p_points'>...</p>
            </div>
            <AnimationLoading/>
        </div>
    )
}

export default ProcessingAccess;