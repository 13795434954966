import React, { useContext, useState } from 'react';
import { Button, Modal, Input, Form } from  'antd';
import { DealershipContext } from '../../Context/DealershipContext/DealershipContext';
import { useDealerShips } from '../../Services/useDealerShips';
import './styles.scss'

export const DealershipsEditModal =(

)=>{
    
    const {editDealership, deleteDealership} = useDealerShips()
    const {
        openEditDealership, 
        setOpenEditDealership, 
        selectedDealership, 
        setSelectedDealership
    } = useContext(DealershipContext)


    const [form_edit_dealership] = Form.useForm();
    const [editDealershipData, setEditDealershipData] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
    

    const handleCancel =()=>{
        setOpenEditDealership(false)
        setSelectedDealership(undefined)
        setEditDealershipData(false)
        form_edit_dealership.resetFields()
    }
    
    const handleSave = async(e:{
        name: string,
        address: string
    })=>{
        
        if(selectedDealership){
            if(selectedDealership){
                await editDealership(selectedDealership.id, e.name, e.address, handleCancel)  
            }else{
                setOpenDeleteModal(true)
            }              
        }            
    }

    const DeletedModal = ()=>{
        return(
            <>
            <Modal   
                title='Eliminar' 
                open={openDeleteModal}
                className='modal buttons_modal'
                onCancel={()=>{
                    setOpenDeleteModal(false)
                    setSelectedDealership(undefined)
                }}
                onOk={async()=>{
                    if(selectedDealership) await deleteDealership(selectedDealership.id, handleCancel)}}
            >
                <p>¿Está seguro que desea eliminar el mantenimiento de {selectedDealership?.name}?</p>
                
            </Modal>
            </>
        )
    }

    return(
        <>
            <Modal   
                title={selectedDealership?.name} 
                open={openEditDealership}
                onCancel={handleCancel}
                className='modal'
                footer={[
                    <Button 
                        key="cancel" 
                        className='global_btn-modal-ghost'
                        //loading={loading}
                        onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button 
                        key="edit" 
                        className='global_btn-modal-ghost'
                        onClick={()=>{
                            setEditDealershipData(!editDealershipData)
                        }}
                    >
                        {editDealershipData ? 'Cancelar edición' : 'Editar' }
                    </Button>,
                    <Button
                        key="save"
                        type="primary"
                        className='global_btn-modal-primary'
                        htmlType="submit"
                        form='form_edit_dealership'
                        onClick={()=>{
                            if(!editDealershipData){
                                setOpenDeleteModal(true)
                            }
                        }}
                    >
                        { editDealershipData ? 'Guardar' : 'Eliminar'}
                    </Button>
                ]}
            >
                <div className='dealershipsEditModal_edit'>
                    
                            { editDealershipData ? <Form 
                                form={form_edit_dealership}
                                id='form_edit_dealership'
                                layout="vertical"
                                onFinish={handleSave}
                            >    
                                
                                <Form.Item
                                    label='Sucursales:'
                                    name='name'
                                    initialValue={selectedDealership?.name}
                                >
                                    <Input
                                        defaultValue={selectedDealership?.name}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Dirección:'
                                    name='address'
                                    initialValue={selectedDealership?.address}
                                >
                                    <Input
                                        defaultValue={selectedDealership?.address}
                                    />
                                </Form.Item>
                            </Form> 
                            :
                            <div>
                                <p>Sucursal: <h4>{selectedDealership?.name}</h4></p>
                                <p>Dirección: <h4>{selectedDealership?.address}</h4></p>
                            </div>}  
                </div>            
            </Modal>
            {openDeleteModal && <DeletedModal/>}
        </>
    )}
  
export default DealershipsEditModal;

