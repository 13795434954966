import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import './styles.scss'
import { useGetUser } from '../../Services/useGetUser';

export const Home: React.FC =()=>{

    const {data: userData} = useGetUser()
    return(
        <div className="home_container">
            <div className='home_title_container'>
                <HomeOutlined style={{ fontSize: '18px', color: 'white'}} />    
                <p>Inicio</p>
            </div>
            <div className='home_body_container'>
                <div  className='home_body_data'>
                    <h1>INFORMACIÓN DE USUARIO:</h1>
                    <p>Usuario: <h2>{userData?.user.username}</h2></p>
                    <p>email: <h2>{userData?.user.email}</h2></p>
                    <p>Nombre: <h2>{userData?.firstname}</h2></p>
                    <p>Apellido: <h2>{userData?.lastname}</h2></p> 
                </div>
               
            </div>
        </div>
    )
}

export default Home;

