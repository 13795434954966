import {  useEffect } from 'react';
import  { KeyedMutator } from "swr";
import {API_END_POINT_AUTH_BOGLICH_USERS} from '../Axios/johnDeereEndPoinds'
import { useAuthSWR } from './useAuthSWR';

export interface BoglichUsers  {
  id: number,
  user: {
      username: string,
      email: string,
  },
  firstname: string,
  lastname: string,
  is_active: boolean
}


export const useGetBoglichUsers = () => {
  const {data, error, mutate}:{
    data:  BoglichUsers[], 
    error: any,
    mutate: KeyedMutator<any>
  } = useAuthSWR([API_END_POINT_AUTH_BOGLICH_USERS])


  useEffect(() => {
    if (!data  && !error) {
      mutate();
    }
  }, [data, mutate]);
  
    return {
      data,
      mutate,
    };
};
