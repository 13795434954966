import { useEffect } from 'react';
import { KeyedMutator } from "swr";
import {API_END_PONT_MACHINE_GET_SIMPLIFIED_EQUIPMENT_TYPES} from '../Axios/johnDeereEndPoinds';
import { useAuthSWR } from './useAuthSWR';


export interface EquipmentType_list{
  id: number,
  name: string
}

export const useGetMachineSimplifiedEquipmentType = () => {
    
    const {data, error, mutate }:{
      data: EquipmentType_list[], 
      error: any,
      mutate: KeyedMutator<any>
    } = useAuthSWR([API_END_PONT_MACHINE_GET_SIMPLIFIED_EQUIPMENT_TYPES])

    
    useEffect(() => {
      if (!data && !error) {
        mutate();
      }
    }, [data, mutate]);
  
    return {
      data,
      mutate,
    };
};
