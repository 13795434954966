import React, { useContext, useState } from 'react'
import { axiosMyJohnDeereInstance } from '../Axios';
import{
    API_END_POINT_DEALERSHIP_CREATE, 
    API_END_POINT_DEALERSHIP_DELETE,
    API_END_POINT_DEALERSHIP_UPDATE
} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { useGetAllDealershep } from './useGetAllDealershep';

export const useDealerShips =  ()=>{

    const { mutate } = useGetAllDealershep()

    const {myJohnDeereToken} = useContext(UserContext);

    const createDealerShips = async (newDealerShips: {name: string, address: string})=>{
        try{
           const data = await axiosMyJohnDeereInstance.post(
                API_END_POINT_DEALERSHIP_CREATE,
                newDealerShips,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                mutate()
                notification.success({
                    message: 'Éxito',
                    description:'Organización creada con éxito', 
                    placement: 'topRight'
                })
               // mutate()   
               // setloadingBtnSave(false)
                return 'ok'         
            }    
           //setloadingBtnSave(false)
        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            //setloadingBtnSave(false)
        } 
    }

    const editDealership = async(
        id: number,
        name: string,
        address: string,
        handleCancel: Function)=>{
        
        let update={
            name: name,
            address: address
        }
        try{
           const data = await axiosMyJohnDeereInstance.patch(
            API_END_POINT_DEALERSHIP_UPDATE + id,
                update,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Mantenimiento actualizado con éxito', 
                    placement: 'topRight'
                })
                mutate()
                handleCancel()                 
            }    

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            handleCancel()
        } 
    }


    const deleteDealership = async(id: number, callback: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.delete(
            API_END_POINT_DEALERSHIP_DELETE + id,              
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Sucursal eliminada con éxito', 
                    placement: 'topRight'
                })
                mutate()
                callback()                 
            }    

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            callback()      
        } 
    }



   
    
   return{
    createDealerShips,
    deleteDealership,
    editDealership
    
    }
}