import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import arg from './i18n/arg.json'
import eng from './i18n/arg.json'
import 'typeface-quicksand';


const messages: any = {
  'es': arg,
  'en': eng,
};

const language: string = navigator.language.split(/[-_]/)[0];



ReactDOM.render(
  <React.StrictMode>
     <IntlProvider
      locale={language}//{'es-AR'} // {language}
      defaultLocale="eng-AR"
      messages={messages[language]}
    >
      <App/>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
