import { useState, useCallback } from 'react';
import { axiosMyJohnDeereInstance } from '../Axios';

type SetStateAction<T> = (prevState: T) => void;

export const useLocalStorageState = <T>(
  key: string,
  defaultValue: T
): [T, SetStateAction<T>] => {

  const loadTokenInHeader = (token: string)=>{
    axiosMyJohnDeereInstance.defaults.headers.common[
      'Authorization'
    ] = `JWT ${token}`          
  }

  const [state, setState] = useState(() => {
    try {
      const localStorageValue = window.localStorage.getItem(key);
      if (localStorageValue !== null) {   
        let parseToken = JSON.parse(localStorageValue)  
        key === 'MyJohnDeere-Token' && loadTokenInHeader(parseToken)
        return parseToken;       
      } else {
        return defaultValue;
      }
    } catch (e) {
      return defaultValue;
    }
  });

  const setLocalStorageState = useCallback(
    (newState: any): void => {
      const newStateString = JSON.stringify(newState)
      window.localStorage.setItem(key, newStateString);
      setState(newState);
      key === 'MyJohnDeere-Token' && loadTokenInHeader(newState)
    },
    [key]
  );

  return [state, setLocalStorageState];
};