import { axiosMyJohnDeereInstance } from '../Axios';
import{
    API_END_POINT_MACHINE_ALERT_UPDATE
} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { useErrorHandling } from './useErrorHandling';


export const useAlerts =  ()=>{

    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }
 

    const editAlert= async( 
        alertId: number,
        newData: {
            status?: number,
            note?: string
            observations?: string,          
        }, 
        callback: Function,
        type: string,
        ): Promise<any>=>{      
       
        
        if (newData.status === undefined || newData.status === null) {
            delete newData.status;
        }
        if (newData.note === undefined || newData.note === null) {
            delete newData.note;
        }
        if (newData.observations === undefined || newData.observations === null) {
            delete newData.observations;
        }
        try{
            const data = await axiosMyJohnDeereInstance.patch(
                `${API_END_POINT_MACHINE_ALERT_UPDATE}/${alertId}`,
                newData             
            )
            const status = errorHandling(data).request.status
            if(status === 200){
                callback()
                notification.success({
                    message: 'Éxito', 
                    description:
                    type + ' modificada con éxito', 
                    placement: 'topRight'
                })
                return data.data 
            }
        }catch(e){
            callback()
            console.log('Error: ' + e)
            notificationError()
        }
    } 
    
   return{
        editAlert
    }
}