import { Navigate, Route, Routes } from 'react-router-dom';
import ProcessingAccess from '../../Components/ProcessingAccess/ProcessingAccess';
import AccessPage from '../../Pages/AccessPage/AccessPage';
import { Dashboard } from '../../Pages/Dashboard/Dashboard';

export const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/auth_johndeere/logincallback/" element={<ProcessingAccess/>} /> 
            <Route path="/" element={<AccessPage/>} /> 
            <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
    );
};




