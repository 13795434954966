import React, {} from 'react';
import {Form,  Modal, Button, Input } from 'antd';
import { OrganizationModalProps } from './type';
import { useDealerShips } from '../../Services/useDealerShips';


export const OrganizationModal: React.FC<OrganizationModalProps> = ({ 
  setOpenModalCreate,
  openModalCreate
})=>{

  const {createDealerShips} = useDealerShips()
  const [form] = Form.useForm(); 

  const handleCancel = () => {
    setOpenModalCreate(false);
  };

  const handleSave = async(e: {name: string, address: string})=>{
    let newDealerShips={
      name: e.name,
      address: e.address
    }
    const data = await createDealerShips(newDealerShips)   
    if(data) {
      handleCancel()
    } 
  }

  return(
      <>
      <Modal 
        title='Crear sucursal' 
        open={openModalCreate}
        onCancel={handleCancel}
        footer={[
          <Button 
            key="cancel" 
            className='global_btn-modal-ghost'
            //loading={loading}
            onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            key="save"
            type="primary"
            className='global_btn-modal-primary'
            //loading={loadingSave}
            htmlType="submit"
            form='form-create'
          >
            Guardar
          </Button>,
        ]}
     >
        <div className='organizationsModal_select modal'>
          <Form
            form={form}
            id='form-create'
            onFinish={handleSave}
           
            >
              <Form.Item
                name='name' 
              >
                <Input
                  placeholder='Nombre'
                />
              </Form.Item>
              <Form.Item
                name='address' 
              >
                <Input
                  placeholder='Dirección'
                />
              </Form.Item>
          </Form>
        </div>          
      </Modal>
    </>)
}