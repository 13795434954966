import React, { useContext,  useState } from 'react';
import { Button, Modal, Input, Form, DatePicker, Select, FormInstance } from  'antd';
import { useMaintenance } from '../../Services/useMaintenance';
import { MaintenanceContext } from '../../Context/ManitenanceContext/MaintenanceContext';
import dayjs from 'dayjs';
import { BoglichUsers, useGetBoglichUsers } from '../../Services/useGetBoglichUsers';

export interface MaintenanceEditModalProps{
    handleGetPage: Function,
}

export const MaintenanceEditModal: React.FC<MaintenanceEditModalProps> =(
    {
        handleGetPage
    }
)=>{

    const {editMaintenance, deleteMaintenance} = useMaintenance()
    const {data: boglichUsers} = useGetBoglichUsers()
    const {Option} = Select
    const { TextArea } = Input;
    
    const { selectedMaintenance, setSelectedMaintenance, openEditMaintenance, setOpenEditMaintenance } = useContext(MaintenanceContext)


    const [form_edit_maintenance] = Form.useForm();
    const [editDataMaintenance, setEditDataMaintenance] = useState<boolean>(false)
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

    const handleCancel =()=>{
        setOpenEditMaintenance(false)
        setSelectedMaintenance(undefined)
        setEditDataMaintenance(false)
        form_edit_maintenance.resetFields()
    }

    const handleSave = async(e:{
        comment: string
        date:string
        service: string
        user: number
    })=>{
        
        if(selectedMaintenance){
            if(editDataMaintenance){
                const data =await editMaintenance(selectedMaintenance.id, e, handleCancel)                  
                if(data === 200){
                    handleGetPage()
                }
            }else{
                alert('deleted')
                setOpenDeleteModal(true)
            }              
        }            
    }

    const DeletedModal = ()=>{
        return(
            <>
            <Modal   
                title='Eliminar' 
                open={openDeleteModal}
                className='modal buttons_modal'
                onCancel={()=>{
                    setOpenDeleteModal(false)
                }}
                onOk={async()=>{
                    if(selectedMaintenance) await deleteMaintenance(selectedMaintenance.id, handleCancel)}}
            >
                <p>¿Está seguro que desea eliminar el mantenimiento de {selectedMaintenance?.machine.name}?</p>
                
            </Modal>
            </>
        )
    }

        return(
            <>
            <Modal   
                title={selectedMaintenance?.machine.name} 
                open={openEditMaintenance}
                onCancel={handleCancel}
                className='modal'
                footer={[
                    <Button 
                        key="edit" 
                        className='global_btn-modal-ghost'
                        onClick={()=>{
                            setEditDataMaintenance(!editDataMaintenance)
                        }}
                    >
                        {editDataMaintenance ? 'Cancelar edición' : 'Editar' }
                    </Button>,
                    <Button 
                        className='global_btn-modal-ghost'
                        key="cancel " 
                        //loading={loading}
                        onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button
                        className='global_btn-modal-primary'
                        key="save"
                        type="primary"
                        htmlType="submit"
                        form='form_edit_maintenance'
                        onClick={()=>{
                            if(!editDataMaintenance){
                                setOpenDeleteModal(true)
                            }
                        }}
                    >
                       { editDataMaintenance ? 'Guardar' : 'Eliminar'}
                    </Button>
                ]}
            >
                <div className='maintenanceConfigurations_seeMore'>

                  
                    {
                        editDataMaintenance 
                            ? 
                            <Form 
                                form={form_edit_maintenance}
                                id='form_edit_maintenance'
                                layout="vertical"
                                onFinish={handleSave}
                            >    
                                <Form.Item
                                    label='Fecha de servicio:'
                                    name='date'
                                    initialValue={dayjs(selectedMaintenance?.date, 'YYYY/MM/DD')}
                                >
                                    <DatePicker
                                        defaultValue={dayjs(selectedMaintenance?.date, 'YYYY/MM/DD')}
                                    />
                                </Form.Item>                                
                                <Form.Item
                                    label='Servicio:'
                                    name='service'
                                    initialValue={selectedMaintenance?.engine_hours}
                                >
                                    <Input
                                        defaultValue={selectedMaintenance?.engine_hours}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Usuario:'
                                    name='user'
                                    initialValue={selectedMaintenance?.usuario.id}
                                >
                                    <Select
                                        placeholder='Usuario'
                                    >
                                        {boglichUsers &&
                                            boglichUsers.map((boglichUser: BoglichUsers) => (
                                            <Option value={boglichUser.id} key={boglichUser.id}>
                                                {boglichUser.user.username}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label='Comentario:'
                                    name='comment'
                                    initialValue={selectedMaintenance?.comment}
                                >
                                    <TextArea
                                        defaultValue={selectedMaintenance?.comment}
                                    />
                                </Form.Item>
                            </Form> 
                            : 
                              <>
                                <p>Fecha de servicio: <h4>{selectedMaintenance?.date}</h4></p>
                                <p>Servicio de: <h4>{selectedMaintenance?.engine_hours} hs</h4></p>
                                <p>Usuario: <h4>{selectedMaintenance?.usuario.user.email}</h4></p>
                                <p>Comentario:<p style={{fontWeight: '500', margin: '0px', paddingLeft:'10px'}}>  {selectedMaintenance?.comment}</p></p>
                              </>
                           
                    }                    
                </div>            
            </Modal>
            {openDeleteModal && <DeletedModal/>}
            </>
        )
    }
  
export default MaintenanceEditModal;

