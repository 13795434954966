import React, { useContext, useState } from "react";
import './styles.scss'
import { EditNotesModalProps } from "./types";
import { Button, Modal, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAlerts } from "../../Services/useAlerts";
import { BodyContext } from "../../Context/BodyContext.tsx/BodyContext";

export const EditNotesModal: React.FC<EditNotesModalProps> = ({
    addNote,
    handleCancel,
    form_edit_note,
    handleGetPage
})=>{

    const {editAlert} = useAlerts()
    const {selectedAlertNotification} = useContext(BodyContext)
    const [loadingBtn, setLoadingBtn] = useState(false)

    const callback =() =>{
        handleCancel()
        setLoadingBtn(false)
        handleGetPage(1, false)
    }

    const handleSave =(e: {note:string})=>{
        setLoadingBtn(true)
        const newData ={
            note: e.note
        }
        if(addNote && addNote.alertId){
            editAlert(addNote.alertId, newData, callback, 'Nota')
        }
       
    }

    return(
        <Modal 
            title='Nota' 
            open={addNote.show}
            className='modal'
            onCancel={handleCancel}
            footer={[
            <Button 
                key="cancel" 
                //loading={loading}
                className='global_btn-modal-ghost'
                onClick={handleCancel}>
                Cancelar
            </Button>,
            <Button
                key="save"
                type='primary'
                className='global_btn-modal-primary'
                //loading={loadingSave}
                htmlType="submit"
                form='form_edit_note'
                loading={loadingBtn}
            >
                Guardar
            </Button>,
            ]}
        >
            <Form
                form={form_edit_note}
                id='form_edit_note'
                layout="vertical"
                onFinish={handleSave}
            >
                <Form.Item
                    name='note'
                >
                    <TextArea 
                        rows={4} 
                        placeholder='Escribe una nota'
                        defaultValue={addNote.note} 
                    />
                </Form.Item>
            </Form>
        
        </Modal>
    )
}

export default EditNotesModal