import React, { useContext } from 'react'
import { axiosMyJohnDeereInstance } from '../Axios';
import{
    API_END_POINT_MAINTENANCE_CREATE_ALERT, 
    API_END_POINT_MAINTENANCE_UPDATE_ALERT,
    API_END_POINT_MAINTENANCE_DELETE_ALERT, 
    API_END_POINT_MAINTENANCE_GET_ALL_ALERT,
} from '../Axios/johnDeereEndPoinds';
import { notification } from 'antd';
import { UserContext } from '../Context/UserContext/UsersContext';
import { MachineryContext } from '../Context/MachineryContext.tsx/MachineryContext';
import { ResponseGetAllTypeMAintenance, useGetAllTypeMaintenance } from './useGetAllTypeMaintenance';
import { useErrorHandling } from './useErrorHandling';
import { FilterValues } from './useGetLeakedNotifications';
import { MaintenanceConfigurationsfiltersType } from '../Components/MaintenanceConfigurationsFilters/type';
import { useGetAllMantenanceAlert } from './useGetAllMantenanceAlert';


export const useMaintenanceConfigurations =  ()=>{


    const {myJohnDeereToken} = useContext(UserContext);
    const {handleEnableCheck, setloadingBtnSave} = useContext(MachineryContext)
    const { mutate } = useGetAllTypeMaintenance()
    const { mutate: mutateAllMaintenance } = useGetAllMantenanceAlert()
    const {errorHandling} = useErrorHandling()

    const notificationError = () =>{
        return  notification.error({
            message: 'Error', 
            description:
              'Hubo un error, por favor comuníquese con el administrador', 
            placement: 'topRight'
        })
    }

    const createMantenanceConfigurations = async(newMantenance: any, handleCancel: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.post(
                API_END_POINT_MAINTENANCE_CREATE_ALERT,
                newMantenance,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Mantenimiento creado con éxito', 
                    placement: 'topRight'
                })
                mutate()   
                setTimeout(()=>{
                    mutateAllMaintenance()
                } , 5000)
                setloadingBtnSave(false)
                handleCancel()
                return 'ok'         
            }    
           setloadingBtnSave(false)

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setloadingBtnSave(false)
            handleCancel()
        } 
    }

    const editMaintenanceConfigurations = async(
        id: number, 
        maintenance_hours: number,
        range_hours: number,
        hours_before_alert: number,
        handleCancel: Function)=>{
        let update ={
            maintenance_hours: maintenance_hours,
            range_hours: range_hours,
            hours_before_alert: hours_before_alert
        } 
        try{
           const data = await axiosMyJohnDeereInstance.patch(
                API_END_POINT_MAINTENANCE_UPDATE_ALERT + id,
                update,               
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Tipo de mantenimiento actualizado con éxito', 
                    placement: 'topRight'
                })
                mutate()
                setTimeout(()=>{
                    mutateAllMaintenance()
                } , 5000)
                handleCancel()                 
            }    

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            handleCancel()
        } 
    }

    const deleteMaintenanceConfigurations = async(id: number, setOpenDeleteModal: Function)=>{
        try{
           const data = await axiosMyJohnDeereInstance.delete(
                API_END_POINT_MAINTENANCE_DELETE_ALERT + id,              
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'JWT ' + myJohnDeereToken,
                    },                    
                }
                )
            if(data){
                notification.success({
                    message: 'Éxito',
                    description:'Mantenimiento eliminado con éxito', 
                    placement: 'topRight'
                })
                mutate()
                setTimeout(()=>{
                    mutateAllMaintenance()
                } , 5000)
                setOpenDeleteModal(false)                 
            }    

        }catch(e){         
            notification.error({
                message: 'Error', 
                description:
                  'Hubo un error, por favor comuníquese con el administrador', 
                placement: 'topRight'
            })
            setOpenDeleteModal(false)      
        } 
    }

    const getMaintenanceConfigurations = async(
        page: number, 
        callback: Function, 
        filters: MaintenanceConfigurationsfiltersType
    ): Promise<ResponseGetAllTypeMAintenance | undefined>=>{
        var params = new URLSearchParams();      
        
        page && params.append("page", page.toString())
        if(filters?.equipment_type){
            filters.equipment_type.map((item: FilterValues)=>{
                params.append("equipment_type", item.key.toString())
            })           
        }
      
        filters?.hours && params.append("hours", filters.hours.toString())
        try{
            const data = await axiosMyJohnDeereInstance.get(
                API_END_POINT_MAINTENANCE_GET_ALL_ALERT,
                {
                    params
                }               
            )
            const status = errorHandling(data).request.status
            if(status === 200){
                callback()
              return data.data 
            }
        }catch(e){
            console.log('Error: ' + e)
            notificationError()
        }
    } 

    
   return{
    createMantenanceConfigurations,
    editMaintenanceConfigurations,
    deleteMaintenanceConfigurations,
    getMaintenanceConfigurations
    }
}